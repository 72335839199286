import React, { Component } from "react";
import NavSidebar from "../../components/NavSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TableWrapper from "../../components/TableWrapper";
import axios from "axios";

import "../../base-css.css";
import { Table} from "antd";
import PlansHeader from "../../components/PlansHeader";
import { Link } from "react-router-dom";
import { openNotificationWithIcon } from "../../lib/notifications";

const status = [
    { 'id': 1, 'name': 'Drafted' },
    { 'id': 2, 'name': 'Public' },
    { 'id': 3, 'name': 'Private' },
    { 'id': 4, 'name': 'Renewal Only' },
    { 'id': 5, 'name': 'Trashed' }
]

const columns = [
    {
        title: "Name",
        key: "name",
        render: (text, record) => (
            <>
                <Link  to={`/plan/${record.id}`}>
                    <span style={{ color: "#2C3FEE" }}>{record.name}</span>
                </Link>
            </>
        )
    },
    {
        title: "Price",
        key: "price",
        render: (text, record) => (
            <>
                <span>${record.price.toFixed(2)}</span>
            </>
        ),
        sorter: {
          compare: (a, b) => a.price - b.price,
          multiple: 1,
        },
    },
    {
        title: "Duration",
        key: "duration",
        render: (text, record) => (
            <>
                <span>{record.durationCount}</span>
                &nbsp;
                <span>{record.durationLabel}</span>
            </>
        )
    },
    {
      title: "Charge period",
      key: "charge",
      render: (text, record) => (
        <>
          <span>{record.chargePeriodCount}</span>
          &nbsp;
          <span>{record.chargePeriodLabel}</span>
        </>
      )
    },
    {
        title: "Active Users",
        dataIndex: "users",
        key: "users"
    },
    {
        title: "Status",
        key: "status",
        sorter: {
          compare: (a, b) => a.status - b.status,
          multiple: 2,
        },
        render: (text, record) => (
            <>
                <span>{status[record.status - 1].name}</span>
            </>
        ),
      },
    ];

class Plans extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        let search = {
            params: this.state.params
        };

        axios
          .get(`/api/subscriptionPlan`, search)
          .then(response => {
              if (response.data.success === true) {
                  const { subscriptionPlans } = response.data.result;
                  this.setState({
                      data: subscriptionPlans,
                  });
              } else {
                  openNotificationWithIcon("error", "Error", response.data.message);
              }
          })
          .catch(function (error) {
            console.log(error);
          });
    }


    render() {
        const {data} = this.state;

        return (
            <>
                <Header />
                <div className="container-custom">
                    <div className="row">
                        <div className="col-lg-2 col-md-3" style={{ marginTop: "70px" }}>
                            <NavSidebar />
                        </div>
                        <div className="col" style={{ marginTop: "70px" }}>
                            <h3>Plans</h3>

                            <PlansHeader
                                // from={from}
                                // to={to}
                                // allCount={allCount}
                                // onChangeCalendarFrom={this.onChangeCalendarFrom}
                                // onChangeCalendarTo={this.onChangeCalendarTo}
                            />

                            <div style={{ marginTop: "60px" }}>
                                <TableWrapper>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                    />
                                </TableWrapper>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer bordered={true} />
            </>
        );
    }
}

export default Plans;
