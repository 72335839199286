import React from "react";

import "./PacksHeader.css";
import IllustrationCounter from "./IllustrationCounter";
import CalendarCustom from "./CalendarCustom";
import ButtonIcon from "./ButtonIcon";
import { IconPlus} from "./Icons";
import { Link } from "react-router-dom";

function PacksHeader(props) {
  const {
    from,
    to,
    allCount,
    onChangeCalendarFrom,
    onChangeCalendarTo
  } = props;

  return (
    <div className="packs-header" style={{ marginTop: "50px" }}>
      <div className="row">
        <div className="col-xl-6 col-lg-6 d-flex align-items-center left-column">
          <Link to={{ pathname: '/pack/create' }} >
            <ButtonIcon class="black">
              <span>
                <IconPlus />
              </span>
              <span>Add product</span>
            </ButtonIcon>
          </Link>
        </div>
        <div className="col d-flex right-column">
          <CalendarCustom
            onChangeCalendarTo={onChangeCalendarTo}
            onChangeCalendarFrom={onChangeCalendarFrom}
          />
        </div>
        <div className="col-12" style={{ marginTop: "30px" }}>
          <IllustrationCounter from={from} to={to} allCount={allCount} />
        </div>
      </div>
    </div>
  );
}

export default PacksHeader;
