import React from "react";

import "./IllustrationCounter.css";

function IllustrationCounter(props) {
  const {from, to, allCount} = props;

  return (
    <div className="illustration-counter">
      <span>{from}</span>
      {from < to ? (
        <>
          -<span>{to}</span>
        </>
      ) : null}
      &nbsp; out of &nbsp;<span>{allCount}</span>
    </div>
  );
}

export default IllustrationCounter;
