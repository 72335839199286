const userReducer = (state = { user: { name: null, email: null } } , action) => {
  switch (action.type) {
    case 'SET_USER_DATA':
    return {
      user: action.payload,
    }
    case 'CLEAR_USER_DATA':
    return {
      user: action.payload,
    }
    default:
     return state
  }
};

export default userReducer;
