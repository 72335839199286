import React, { Component } from "react";
import NavSidebar from "../../components/NavSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TableWrapper from "../../components/TableWrapper";
import StatisticCard from "../../components/StatisticCard";
import WidgetPack from "../../components/WidgetPack";
import axios from "axios";
import moment from "moment";
import "../../base-css.css";
import "./index.css";
import { Table, Pagination, Avatar, DatePicker} from "antd";
import Calendar from "../../components/Calendar";
import PeriodTabs from "../../components/PeriodTabs";
import PeriodTabsItem from "../../components/PeriodTabsItem";
import { getRequestPeriod } from "../../utils/utils";
import ButtonIcon from "../../components/ButtonIcon";
import { Redirect, Link } from "react-router-dom";
import {IconEscape, IconOpen, IconEdit} from "../../components/Icons";
import { openNotificationWithIcon } from "../../lib/notifications";

const datePickerDateFormat = "Do MMM YY";

const status = [
  { 'id': 1, 'name': 'Drafted' },
  { 'id': 2, 'name': 'Review' },
  { 'id': 3, 'name': 'Published' },
  { 'id': 4, 'name': 'Trashed' }
]

const columns = [
  {
    title: "#",
    key: "id",
    render: (text, record, index) => index + 1,
  },
  {
    title: "image",
    key: "name",
    dataIndex: "cover",
    render: (text, record) => (
      <>
        <Avatar shape="square" size={50} src={record.cover} />
      </>
    )
  },
  {
    title: "Name",
    key: "name",
    render: (text, record) => (
      <>
        <span style={{ color: "#2C3FEE" }}>{record.name}</span>
      </>
    )
  },
  {
    title: "Views",
    key: "views",
    render: () => "No status",
  },
  {
    title: "Downloads",
    dataIndex: "downloadCount",
    key: "name"
  }
];

const packColumns = [
  {
    title: "Author",
    key: "name",
    render: () => (
      <>
        <span style={{ color: "#2C3FEE" }}>Craftwork</span>
      </>
    )
  },
  {
    title: "Status",
    key: "status",
    render: (text, record) => (
      <>
        <span>{status[record.status - 1].name}</span>
      </>
    )
  },
  {
    title: "Assets",
    dataIndex: "illustrationCount",
    key: "name"
  },
  {
    title: "Mood",
    key: "mood",
    render: (_text, record) => {
      try { return record.mood.name}
      catch(err) { return '' }
    }
  },
  {
    title: "Creation Date",
    key: "createdDate",
    render: (text, record) => moment(record.createdDate).format(datePickerDateFormat)
  }
];

class Packs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      fixedPeriod: "Month",
      error: false,
      packInfo: {
        name: "",
        cover: null
      },
      packData: [],
      pagination: {
        params: {
          to: null,
          from: null,
          allCount: null,
          allPage: 1,
          currentPage: 1
        }
      },
      previous: {
        params: {
          from: moment()
            .subtract(2, "month")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "month")
            .format("YYYY-MM-DD")
        },
        data: []
      },
      current: {
        params: {
          from: moment()
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD")
        },
        data: []
      },
      singlePeriod: false,
      duringDays: ""
    };

    this.getStatistic = this.getStatistic.bind(this);
    this.getPackInfo = this.getPackInfo.bind(this);
    this.getIllustrations = this.getIllustrations.bind(this);

    this.calendarOnChange = this.calendarOnChange.bind(this);
    this.changeRequest = this.changeRequest.bind(this);
    this.onChangePagination = this.onChangePagination.bind(this);
  }

  onChangePagination(page) {
    this.setState(
      prevState => {
        let pagination = Object.assign({}, prevState.pagination);
        pagination.params.currentPage = page;
        return { pagination };
      },
      () => this.getIllustrations()
    );
  }

  getPackInfo() {
    axios
      .get(`/api/pack/${this.props.match.params.id}`)
      .then(response => {

        const dataObject = response.data.result.packData;


        if (dataObject === null) {
          this.setState({
            error: true
          });
        }
        if (response.data.success && (dataObject !== null)) {
          const data = response.data.result.packData;
          const { name, cover } = response.data.result.packData;

          this.setState(prevState => {
            let packInfo = Object.assign({}, prevState.packInfo);
            packInfo.name = name;
            packInfo.cover = cover;

            return {
              packInfo,
              packData: [data]
            };
          });
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            response.data.message
          );
          console.log(this);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getIllustrations() {
    const from = this.state.current.params.from;
    const to = this.state.current.params.to;
    const page = this.state.pagination.params.currentPage;

    let search = {
      params: {
        from,
        to,
        page
      }
    };

    axios
      .get(
        `/api/illustration?packId=${this.props.match.params.id}`,
        search
      )
      .then(response => {
        if (response.data.success === true) {
          this.setState(prevState => {
            let pagination = Object.assign({}, prevState.pagination);
            pagination.params.from = response.data.result.pagination.from;
            pagination.params.to = response.data.result.pagination.to;
            pagination.params.currentPage =
              response.data.result.pagination.currentPage;
            pagination.params.allPage = response.data.result.pagination.allPage;

            return {
              pagination,
              data: response.data.result.illustrationData
            };
          });
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            response.data.message
          );
          console.log(this);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getStatistic(requestParams, targetStat) {
    axios
      .get(
        `/api/illustrationLog/getMarketingStatistic?packId=${this.props.match.params.id}`,
        { params: requestParams },
        { timeout: 3000 }
      )
      .then(response => {
        if (response.data.success) {
          this.setState({
            [targetStat]: {
              params: requestParams,
              data: response.data.result.marketingStatistic
            }
          });
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            response.data.message
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeRequest(value) {
    const dataObject = getRequestPeriod(value);

    this.setState(
      prevState => {
        let current = Object.assign({}, prevState.current);
        current.params.from = dataObject.curFrom;
        current.params.to = dataObject.curTo;

        let previous = Object.assign({}, prevState.previous);
        previous.params.from = dataObject.prevFrom;
        previous.params.to = dataObject.prevTo;

        return {
          current,
          previous,
          singlePeriod: false,
          fixedPeriod: dataObject.fixedPeriod
        };
      },
      () => {
        this.getStatistic(this.state.current.params, "current");
        this.getStatistic(this.state.previous.params, "previous");
        this.getIllustrations();
      }
    );
  }

  calendarOnChange(date, dateString, pickerInd) {
    this.setState(
      prevState => {
        let current = Object.assign({}, prevState.current);

        return {
          current,
          fixedPeriod: null,
          singlePeriod: true
        };
      },
      () => {
        this.getStatistic(this.state.current.params, "current");
        this.getIllustrations();
        this.setState({
          duringDays: this.countDays(
            this.state.current.params.from,
            this.state.current.params.to
          )
        });
      }
    );
  }

  countDays(start, end) {
    return moment.duration(moment(end).diff(moment(start))).asDays();
  }

  componentDidMount() {
    this.getPackInfo();
    this.getStatistic(this.state.previous.params, "previous");
    this.getStatistic(this.state.current.params, "current");
    this.getIllustrations();
  }

  render() {
    const { data, allCount, packInfo, packData, error } = this.state;
    const { cover, name } = packInfo;

    return (
      <div className="view-pack">
        { error === true ? <Redirect to="/404" /> : null }
        <Header />
        <div className="container-custom">
          <div className="row">
            <div className="col-lg-2 col-md-3" style={{ marginTop: "70px" }}>
              <NavSidebar />
            </div>
            <div className="col" style={{ marginTop: "70px" }}>
              <div className="row">
                <div className="col-12">
                  <Link
                    to={`/packs`}
                  >
                    <ButtonIcon class="neutral outline escape">
                      <span>
                        <IconEscape />
                      </span>
                      <span>Packs</span>
                    </ButtonIcon>
                  </Link>
                </div>
                <div className="col-12" style={{ marginTop: "50px" }}>
                  <WidgetPack cover={cover} name={name} id={this.props.match.params.id} />
                </div>
              </div>

              <div className="row table-row">
                <div
                  className="col-lg-8 left-column"
                  style={{ marginTop: "40px" }}
                >
                  <TableWrapper>
                    <Table
                      className="no-border"
                      columns={packColumns}
                      dataSource={packData}
                      pagination={false}
                    />
                  </TableWrapper>
                </div>
                <div
                  className="col-lg-4 right-column"
                  style={{ marginTop: "40px" }}
                >
                  <div className="button-wrapper">
                    <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_STORYTALE_APP}/pack/${this.props.match.params.id}`}>
                      <ButtonIcon class="purple small">
                        <span>
                          <IconOpen />
                        </span>
                        <span>Open Page</span>
                      </ButtonIcon>
                    </a>

                    <Link
                      to={`/pack/edit/${this.props.match.params.id}`}
                    >
                      <ButtonIcon class="black small">
                        <span>
                          <IconEdit />
                        </span>
                        <span>Edit pack</span>
                      </ButtonIcon>
                    </Link>

                  </div>
                </div>
                <div className="col-lg-12">
                  <div
                    style={{
                      borderBottom: "1px solid #f2f2f2",
                      paddingTop: "25px"
                    }}
                  ></div>
                </div>
              </div>

              <h4 style={{ marginTop: "70px" }}>Statistic</h4>
              <div className="row">
                <div className="col-auto" style={{ marginTop: "30px" }}>
                  <PeriodTabs>
                    <PeriodTabsItem
                      isActive={this.state.fixedPeriod === "Day"}
                      onClick={value => this.changeRequest(value)}
                      name="Day"
                    />
                    <PeriodTabsItem
                      isActive={this.state.fixedPeriod === "Week"}
                      onClick={value => this.changeRequest(value)}
                      name="Week"
                    />
                    <PeriodTabsItem
                      isActive={this.state.fixedPeriod === "Month"}
                      onClick={value => this.changeRequest(value)}
                      name="Month"
                    />
                    <PeriodTabsItem
                      isActive={this.state.fixedPeriod === "Year"}
                      onClick={value => this.changeRequest(value)}
                      name="Year"
                    />
                  </PeriodTabs>
                </div>
                <div className="col-auto" style={{ marginTop: "30px" }}>
                  <Calendar
                    startDatePicker={
                      <DatePicker
                        value={moment(this.state.current.params.from)}
                        defaultValue={moment(this.state.current.params.from)}
                        format={datePickerDateFormat}
                        onChange={(date, dateString) =>
                          this.calendarOnChange(date, dateString, "fromPicker")
                        }
                        allowClear={false}
                      />
                    }
                    endDatePicker={
                      <DatePicker
                        value={moment(this.state.current.params.to)}
                        defaultValue={moment(this.state.current.params.to)}
                        format={datePickerDateFormat}
                        onChange={(date, dateString) =>
                          this.calendarOnChange(date, dateString, "toPicker")
                        }
                        allowClear={false}
                      />
                    }
                  />
                </div>
              </div>

              <div style={{ marginTop: "60px" }}>
                <div className="row statistic-row">
                  <div className="col-lg-3 left-column">
                    {Object.keys(this.state.current.data).map(
                      (keyName, index) => (
                        <div style={{ marginBottom: "30px" }} key={index}>
                          <StatisticCard
                            name={keyName}
                            value={this.state.current.data[keyName]}
                            prevValue={this.state.previous.data[keyName]}
                            isSingle={this.state.singlePeriod}
                            days={Math.trunc(this.state.duringDays)}
                          />
                        </div>
                      )
                    )}
                  </div>
                  <div className="col-lg-9 right-column">
                    <TableWrapper>
                      <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                      />
                      {this.state.pagination.params.currentPage <=
                        this.state.pagination.params.allPage ? (
                          <div style={{ marginTop: "40px" }}>
                            <Pagination
                              defaultCurrent={1}
                              total={this.state.pagination.params.allPage + "0"}
                              onChange={this.onChangePagination}
                            />
                          </div>
                        ) : null}
                    </TableWrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer bordered={true} allCount={allCount} />
      </div>
    );
  }
}

export default Packs;
