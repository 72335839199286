import React from "react";

import "./HeaderPanel.css";
import IllustrationCounter from "./IllustrationCounter";
import CalendarCustom from "./CalendarCustom";

function HeaderPanel(props) {
  const {
    from,
    to,
    allCount,
    onChangeCalendarFrom,
    onChangeCalendarTo
  } = props;

  return (
    <div className="header-panel" style={{ marginTop: "60px" }}>
      <div className="row">
        <div className="col-lg-2 col-md-3 column left-column">
          <IllustrationCounter from={from} to={to} allCount={allCount} />
        </div>
        <div className="col d-flex column right-column">
          <CalendarCustom
            onChangeCalendarTo={onChangeCalendarTo}
            onChangeCalendarFrom={onChangeCalendarFrom}
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderPanel;
