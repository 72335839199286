import React from "react";
import "./WidgetPack.css";
import { Link } from "react-router-dom";

function WidgetPack(props) {
 
  return (
    <div
      className={`widget-pack`}
      onClick={props.onClick || null}
    >
      <div
        className={`poster bordered`}
      >
        <Link className="image-link" to={`/pack/edit/${props.id}`}>
          <img src={props.cover} alt="" />
        </Link>
      </div>
      <Link className="text-link" to={`/pack/edit/${props.id}`}>
          <h3 className="title">{props.name}</h3>
      </Link>
    </div>
  );
}

export default WidgetPack;
