import React from 'react';
import { Link } from 'react-router-dom';

//lib, helpers
import { ErrorLoger } from '../lib/errorLogger';

import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      logs: [],
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let errorLogger = new ErrorLoger();
    let regexp = /\(([^()]+(\([^)]+\)[^)]*)*)\)/g;
    let result = error.stack.match(regexp);

    let fmtd_result = result.map(url => {
      return {
        url: `${url.replace(/[()]/g, '').split('.js')[0]}.js`,
        lineCol: url.replace(/[()]/g, '').split('.js')[1],
      }
    });

    errorLogger.sendError(error.message, errorInfo, fmtd_result, this.props.children.props.location, this.props.children.props);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='ErrorBoundary'>
          <div className='container-custom'>
            <div className='row'>
              <div className='col'>
                <h1 className='text-center ErrorBoundary__title'>Something went wrong.</h1>
                <p className='text-center ErrorBoundary__text'>Please try again later</p>
                <Link to='/' >Go to main page</Link>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
  }

  export default ErrorBoundary;
