import React, {PureComponent} from "react";
import NavSidebar from '../../components/NavSidebar';
import {Link, Redirect} from "react-router-dom";
import { Form, Input, Button, Select, Row, Col } from 'antd';
import axios from 'axios';
import {IconEscape} from '../../components/Icons';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ButtonIcon from "../../components/ButtonIcon";
import { withRouter } from "react-router";
import { openNotificationWithIcon } from "../../lib/notifications";

class PlanEdit extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            createdPackId: '',
            status: [
                { 'id': 1, 'name': 'Drafted' },
                { 'id': 2, 'name': 'Public' },
                { 'id': 3, 'name': 'Private' },
                { 'id': 4, 'name': 'Renewal Only' },
                { 'id': 5, 'name': 'Trashed' }
            ],
            selectedStatus: 1,
            selectedPeriod: 1,
            duration: '',
            charge: '',
            chargePeriod: '',
            period: [
                { 'id': 1, 'name': 'Day' },
                { 'id': 2, 'name': 'Month' },
            ],
            planName: '',
            planPrice: '',
            downloadLimit: '',
            error: false,
        };

        this.formOnFinish = this.formOnFinish.bind(this);
        this.handleSelectValueChange = this.handleSelectValueChange.bind(this);
    }

    onChangeNameValue = (value, index) => {
        const names = this.state.inputsNames.slice(0);
        names[index] = value;

        this.setState({
            inputsNames: names,
        });
    };


    formOnFinish() {
        const {selectedStatus} = this.state;

        axios.put(`/api/subscriptionPlan/${this.props.match.params.id}`, {status: selectedStatus})
            .then(response => {
                if (response.data.success === true) {
                    openNotificationWithIcon('success', 'Success', 'Plan Updated');
                } else {
                    openNotificationWithIcon('error', 'Error', response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSelectValueChange(value) {
        this.setState({
            selectedStatus: value
        })
    }

    handleSelectDurationChange(value) {
        this.setState({
            selectedPeriod : value
        })
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getPlanInfo() {
        axios
            .get(`/api/subscriptionPlan/${this.props.match.params.id}`)
            .then(response => {

                const dataObject = response.data.result.subscriptionPlan;

                if (dataObject === null) {
                    this.setState({
                        error: true
                    });
                }
                if (response.data.success && (dataObject !== null)) {
                    const { name, price, downloadLimit, durationCount, durationLabel, status, description, chargePeriodCount, chargePeriodLabel} = response.data.result.subscriptionPlan;
                    const {period} = this.state;
                    const string = this.capitalizeFirstLetter(durationLabel);
                    let labelId = period.find(item => item.name === string).id
                    const upperChargePeriod = (chargePeriodLabel? (chargePeriodLabel[0].toUpperCase() + chargePeriodLabel.substring(1)) : '');

                    this.setState({
                        planName : name,
                        planPrice: price,
                        downloadLimit: downloadLimit,
                        duration: durationCount,
                        selectedStatus: status,
                        selectedPeriod: labelId,
                        description: description,
                        charge: chargePeriodCount,
                        chargePeriod: upperChargePeriod,
                    })
                } else {
                    openNotificationWithIcon("error", "Error", response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
      this.getPlanInfo();
    }

    render() {
        const {planName , planPrice , downloadLimit, duration, status, selectedStatus, period, selectedPeriod, error, description, charge, chargePeriod} = this.state;
        return (
            <div className="plan-create">
                {error === true ? <Redirect to="/404" /> : null}
                <Header />
                <div className="container-custom">
                    <div className="row content-row">
                        <div className="col-lg-2 col-md-3">
                            <NavSidebar />
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-3 button-column">
                                    <Link to={{ pathname: '/plans' }} >
                                        <ButtonIcon class="neutral outline big">
                                          <span>
                                            <IconEscape />
                                          </span>
                                            <span>Plans</span>
                                        </ButtonIcon>
                                    </Link>
                                </div>
                                <div className="col-lg-5">
                                    <h1 className='dashboard__title'>Edit plan {planName}</h1>
                                    <Form
                                        name='createPlanForm'
                                        className='plan-form'
                                        layout='vertical'
                                        onFinish={this.formOnFinish}
                                    >
                                        <Form.Item
                                            label='Name'
                                            name='planName'
                                            initialValue={planName}
                                        >
                                            <Input disabled  value={planName}/>
                                            <p style={{ fontSize: '0', visibility: 'hidden' }}>{planName}</p>
                                        </Form.Item>
                                        <Form.Item
                                            label='Price'
                                            name='planPrice'
                                            initialValue={planPrice}
                                        >
                                            <Input disabled  value={planPrice} type="number" min="0" step="0.01"/>
                                            <p style={{ fontSize: '0', visibility: 'hidden' }}>{planPrice}</p>
                                        </Form.Item>
                                        <Form.Item
                                            label='Download limit'
                                            name='dowloadLimit'
                                            initialValue={downloadLimit}
                                        >
                                            <Input disabled  value={downloadLimit} type="number" min="0"/>
                                            <p style={{ fontSize: '0', visibility: 'hidden' }}>{downloadLimit}</p>
                                        </Form.Item>
                                        <Row gutter={24}>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label='Duration'
                                                    name='duration'
                                                    onChange={(event) => this.handleInputChange(event, 'duration')}
                                                    initialValue={duration}
                                                >
                                                    <Input disabled value={duration} />
                                                    <p style={{ fontSize: '0', visibility: 'hidden' }}>{duration}</p>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    className="custom-item"
                                                    label='Duration period'
                                                    name='period'
                                                    initialValue={period[selectedPeriod - 1].id}
                                                >
                                                    <p style={{ fontSize: '0', visibility: 'hidden' }}>{period[selectedPeriod - 1].id}</p>
                                                    <Select
                                                        onChange={(value) => this.handleSelectDurationChange(value)}
                                                        value={period[selectedPeriod - 1].id}
                                                        disabled={true}
                                                    >
                                                        {this.state.period.map((stat) =>
                                                            <Select.Option key={stat.id + stat.name} value={stat.id}>{stat.name}</Select.Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                  label='Charge period'
                                                  name='chargeCount'
                                                  initialValue={charge}
                                                >
                                                    <Input disabled value={charge} />
                                                    <p style={{ fontSize: '0', visibility: 'hidden' }}>{charge}</p>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <p className={'charge-label'}>
                                                    {chargePeriod}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            label='Status'
                                            name='status'
                                        >
                                            <p style={{ fontSize: '0', visibility: 'hidden' }}>{status[selectedStatus- 1].id}</p>
                                            <Select
                                                value={status[selectedStatus- 1].id}
                                                onChange={(value) => this.handleSelectValueChange(value)}
                                            >
                                                {this.state.status.map((stat) =>
                                                    <Select.Option key={stat.id + stat.name} value={stat.id}>{stat.name}</Select.Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label='Description'
                                            name='description'
                                            initialValue={description}
                                        >
                                            <Input.TextArea
                                                disabled
                                                maxLength={160}
                                                autoSize={{ minRows: 4, maxRows: 6 }}
                                                value={description}
                                            />
                                            <p style={{ fontSize: '0', visibility: 'hidden' }}>{description}</p>

                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType='submit' style={{ marginTop: '50px' }}>
                                                Update Plan
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer bordered={true} />
            </div>
        )
    }
}

const PlanEditRouter = withRouter(PlanEdit);
export default PlanEditRouter;
