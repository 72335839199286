import React from "react";

import "./Calendar.css";
import moment from "moment";
import { DatePicker } from "antd";
const dateFormat = "Do MMM YY";


export default function CalendarCustom(props) {
    const { onChangeCalendarFrom, onChangeCalendarTo } = props;
    
  return (
    <div className="calendar">
      <div className="wrapper">
        <div className="item">
          <span className="text">from</span>
          <DatePicker
            defaultValue={moment().subtract(1, "month")}
            //disabledDate={d => !d || d.isAfter(moment())}
            format={dateFormat}
            onChange={onChangeCalendarFrom}
            onPanelChange={() => onChangeCalendarFrom}
          />
        </div>
        <div className="item">
          <span className="text">to</span>
          <DatePicker
            defaultValue={moment()}
            //disabledDate={d => !d || d.isBefore(moment())}
            format={dateFormat}
            onChange={onChangeCalendarTo}
            onPanelChange={() => onChangeCalendarTo}
          />
        </div>
      </div>
    </div>
  );
}
