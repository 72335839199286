import React from "react";

import "./PacksHeader.css";
import ButtonIcon from "./ButtonIcon";
import { IconPlus} from "./Icons";
import { Link } from "react-router-dom";

function PlansHeader(props) {

    return (
        <div className="packs-header" style={{ marginTop: "50px" }}>
            <div className="row">
                <div className="col-xl-6 col-lg-6 d-flex align-items-center left-column">
                    <Link to={{ pathname: '/plan/create' }} >
                        <ButtonIcon class="black">
                          <span>
                            <IconPlus />
                          </span>
                          <span>Add plan</span>
                        </ButtonIcon>
                    </Link>
                </div>
                <div className="col d-flex right-column">

                </div>
            </div>
        </div>
    );
}

export default PlansHeader;
