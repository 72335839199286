import React from "react";
import '../../Dashboard.css';
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function PageNotFound() {
  return (
    <>
      <Header />
      <div className="container-custom">
        <div className="row">
          <div className="col" style={{ marginTop: "70px" }}>
            <h1 className='dashboard__heading'>Sorry page not found</h1>
          </div>
        </div>
      </div>
      <Footer bordered={true} />
    </>
  )
}
