/** React imports  */
import React, { PureComponent } from "react";

/** React router */
import { withRouter } from "react-router";
import { Redirect, Link } from "react-router-dom";

/** Third party imports */
import axios from 'axios';
import { Form, Input, Button, Select, Upload, Popconfirm, Tag, Pagination, Row, Col, Switch } from 'antd';
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';

/** App React components */
import { IconCart, IconPlus, IconEscape } from '../../components/Icons';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import NavSidebar from '../../components/NavSidebar';
import CustomTabs from "../../components/CustomTabs";
import ButtonIcon from "../../components/ButtonIcon";

/** App common modules */
import { getAllNames, getAllElements, reduceAllIndexes, setFileImageGroup, findDifferences, getBase64, variableNameFormation } from "../../utils/utils";
import { openNotificationWithIcon } from "../../lib/notifications";

/** Styles */
import './index.css';

const { Dragger } = Upload;

const status = [
  { 'id': 1, 'name': 'Drafted' },
  { 'id': 2, 'name': 'Review' },
  { 'id': 3, 'name': 'Published' },
  { 'id': 4, 'name': 'Trashed' }
];

class PackEdit extends PureComponent {
  editPackFormRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      activeFilter: 'assets',

      // pagination in the assets tab
      pagination: {
        params: {
          allCount: null,
          allPage: 1,
          currentPage: 1
        }
      },

      // states for form selects lists
      // for pack
      types: [],
      moods: [],
      // for assets
      categories: [],

      /** States related to pack information form
       * @todo get rid of packInfo or add all pack related data to packInfo
       * */
      packInfo: {
        id: '',
        name: '',
        cover: '',
        type: '',
        mood: '',
        categories: [],
        keywords: '',
        description: '',
        shortDescription: '',
        status: 1,
      },
      isPackCoverLoaded: null,
      isPackBigCoverLoaded: null,
      packCoverFileList: [],
      packBigCoverFileList: [],
      isPackCoverChange: false,
      isPackBigCoverChange: false,
      presentationFileList: [],
      landingUrl: '',
      figmaPreview: '',
      craftworkId: null,
      highlights: [],
      demoFile: [],
      demoFileStatus: 'empty',

      // states related to assets form:
      updateAssetsButtonDisabled: false,
      requestCounter: {
        activate: false,
        push: 0,
        complete: 0
      },

      // states related to assets form - adding new illustrations
      assetsFileListDnD: [],
      addedAssetsList: [],
      addedAssetsPreviews: [],
      addedAssetsNames: [],
      addedAssetsKeywords: [],
      addedAssetsCategories: [],
      addedAssetsDescriptions: [],
      addedAssetsFreeOptions: [],

      // states related to assets form - edit server illustrations
      serverAssetsData: {
        data: [],
        illustrationsNames: [],
        illustrationsDescriptions: [],
        illustrationsKeywords: [],
        illustrationsCategories: [],
        illustrationsFreeOptions: [],
        illustrationIndexes: [],
        illustrationIndexesCurrent: [],
        changedElements: [],
      },
    };

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.addToChangedElements = this.addToChangedElements.bind(this);
    this.submitPackInformationForm = this.submitPackInformationForm.bind(this);
    this.deletePack = this.deletePack.bind(this);
    this.changeEditPackTab = this.changeEditPackTab.bind(this);
    this.onUpdateAssets = this.onUpdateAssets.bind(this);
    this.addNewAssets = this.addNewAssets.bind(this);
    this.onDeleteAssets = this.onDeleteAssets.bind(this);
    this.onChangePagination = this.onChangePagination.bind(this);
    this.onChangeInLoadedAsset = this.onChangeInLoadedAsset.bind(this);
    this.onChangeInNewlyAddedAsset = this.onChangeInNewlyAddedAsset.bind(this);
  }

  /** Method for change state. Triggers on form item change.
   * @param {string} stateName State name
   * @param {object} event Event object
   */
  handleStateChange(event, stateName) {
    this.setState({ [stateName]: event.target.value })
  };

  // utils
  cutOneElementAtIndex = (array, index) => {
    const copy = [...array];
    copy.splice(index, 1);
    return copy;
  };

  // form handlers -->
  // handlers for pack information form Partial refactored

  /** Method for change substate. Triggers on form item change.
   * @param {string} stateName Parent state name
   * @param {string} subName Substate name
   * @param {object} event Form item change event
   * @param {object} value Value for substate - optional parameter
   */
  handleSubStateChange(stateName, subName, event, value = event.target.value) {
    this.setState(prevState => {
      let state = Object.assign({}, prevState[stateName]);
      state[subName] = value;
      return { [stateName]: state }
    })
  }

  /** Triggers when a coverType deleted from form input
   * @param {coverType} sting
   * @return {null} null
   * */
  onCoverCancel = (coverType) => {
    let coverTypeListName = variableNameFormation({basis: coverType, postfix: 'FileList'});
    let isLoadedStateName = variableNameFormation({basis: coverType, prefix: 'is', postfix: 'Loaded'});
    let isChangedStateName = variableNameFormation({basis: coverType, prefix: 'is', postfix: 'Change'});

    const newFileList = this.cutOneElementAtIndex(this.state[coverTypeListName], 0);

    this.setState({
      [coverTypeListName]: newFileList, [isLoadedStateName]: false, [isChangedStateName]: !this.state[isChangedStateName],
    })
  };

  /** Triggers when a coverType for a pack is loaded
   * @param {object} info Object with information about files upload process information
   * @param {string} coverType
   * @return {null}
   * */
  handleUploadPackCoverType = async (info, coverType) => {
    let coverTypeList = [...info.fileList];
    let coverTypeListName = variableNameFormation({basis: coverType, postfix: 'FileList'});
    let isLoadedStateName = variableNameFormation({basis: coverType, prefix: 'is', postfix: 'Loaded'})

    coverTypeList[0].preview = await getBase64(coverTypeList[0].originFileObj);

    this.setState({ [coverTypeListName]: coverTypeList, [isLoadedStateName]: true });
  };

  /** Triggers when a new image loaded to images array
   * @param {object} info Object with information about files upload process information
   * @return {null}
   * */
  handlePresentationUpload = async info => {
    const presentationFileList = [...info.fileList];
    this.setState({ presentationFileList });
  };

  /** For replace symbols from string
   * @param {string} stateName State name
   * @param {object} event Event object
   * */
  handleInputForNumberChange(event, stateName) {
    let inputValue = event.target.value;
    let newValue = parseInt(inputValue.replace(/[^0-9]/g, ''));
    newValue = (newValue > 2147483647) ? 2147483647 : newValue;

    if (Number(newValue)) {
      let fieldName = event.target.id.split('_')[1];
      this.editPackFormRef.current.setFieldsValue({ [fieldName]: newValue });

      this.setState({ [stateName]: newValue });
    }
  }

  /** For single file state change event
   * @param {object} info Object with information about file upload process information
   * @param {string} stateName Target state name
   * */
  handleFileChangeOnEdit = async (info, stateName) => {
    console.log(info.fileList);
    let loadedFile = [...info.fileList];
    let fileStateName = variableNameFormation({basis: stateName, postfix: 'Status'})

    this.setState({ [stateName]: loadedFile.slice(-1), [fileStateName]: 'changed' })
  }

  handleFormListItemChange(event, index) {
    let highlights = [...this.state.highlights];
    highlights[index] = event.target.value;

    this.setState({ highlights });
  }

  handleFormListItemDelete(itemName) {
    let highlights = [...this.state.highlights]
    highlights.splice(itemName, 1);

    this.setState({ highlights });
  }

  submitPackInformationForm() {
    let formData = new FormData();
    const { name, type, mood, keywords, id, status, description, shortDescription } = this.state.packInfo;
    let { categories } = this.state.packInfo;

    const {
      isPackCoverChange, isPackBigCoverChange, packCoverFileList,
      packBigCoverFileList, figmaPreview, landingUrl,
      craftworkId, presentationFileList, highlights, demoFile, demoFileStatus,
    } = this.state;

    formData.append('id', id);
    formData.append('status', status);
    formData.append('authorId', 1);
    formData.append('typeId', type);
    formData.append('name', name);
    formData.append('moodId', mood);
    if (description) { formData.append('description', description) }
    if (shortDescription) { formData.append('shortDescription', shortDescription) }
    if (figmaPreview) { formData.append('figmaPreview', figmaPreview) }
    if (landingUrl) { formData.append('landingUrl', landingUrl) }
    if (craftworkId) { formData.append('craftworkId', craftworkId) }

    if (highlights.length) {
      for (let i = 0; i < highlights.length; i++) {
        let withoutExtraSpaces = highlights[i]
          .replace(/\s+/g, ' ')
          .replace(/^\s+|\s+$/g, '');
        formData.append('highlights[]', withoutExtraSpaces);
      }
    }

    if (categories.length) {
      for (let i = 0; i < categories.length; i++) {
        formData.append('categoryIds[]', categories[i]);
      }
    } else {
      formData.append('categoryIds[]', '');
    }

    if (isPackCoverChange) {
      formData.append('cover', packCoverFileList[0].originFileObj);
    }

    if (isPackBigCoverChange) {
      formData.append('bigCover', packBigCoverFileList[0].originFileObj);
    }

    if (presentationFileList.length) {
      for (let i = 0; i < presentationFileList.length; i ++) {
        formData.append('presentationImages[]', presentationFileList[i].originFileObj);
      }
    }

    if (demoFileStatus === 'changed') {
      if (demoFile.length && typeof demoFile === 'object') {
        formData.append('demoFile',  demoFile[0].originFileObj);
      } else {
        formData.append('demoFile',  null);
      }
    }

    let keywordsData = (keywords ? keywords.split(',') : null);

    if (keywordsData !== null) {
      for (let i = 0; i < keywordsData.length; i++) {
        const keyword = keywordsData[i].trim();
        if (keyword.length > 0) {
          formData.append('keywords[]', keyword);
        }
      }
    }

    if (demoFile.length) { formData.append('demoFile', demoFile[0].originFileObj) }

    axios.post(`/api/packAction/edit`, formData)
      .then(response => {
        if (response.data.success === true) {
          openNotificationWithIcon('success', 'Success',  `Pack Updated`);
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  deletePack() {
    const { history } = this.props;
    axios.delete(`/api/pack/${this.state.packInfo.id}`)
      .then(response => {
        if (response.data.success === true) {
          history.push("/packs");
          openNotificationWithIcon('success', 'Success',`Pack Deleted`);

        } else {
          openNotificationWithIcon('error', 'Error',response.data.message);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  // * this handlers are unused at the moment *
  /** Then why these methods here? */
  handleCategoryChange(value) {
    this.setState(
      prevState => {
        let packInfo = Object.assign({}, prevState.packInfo);
        packInfo.categories = value;

        return { packInfo, };
      }
    );
  }

  handleSelectDurationChange(value) { this.setState({ selectedPeriod : value })}
  // * end *

  // handlers for assets form
  // submit handlers:

  // total request (add, delete, update asset)
  submitAssetsForm = () => {
    const deletePromises = this.onDeleteAssets();
    const addPromises = this.addNewAssets();
    const updatePromises = this.onUpdateAssets();
    const allPromises = [...deletePromises, ...addPromises, ...updatePromises];

    if (allPromises.length > 0) {
      this.setState({ updateAssetsButtonDisabled: true });

      Promise.all(allPromises)
        .then(() => {

          openNotificationWithIcon('success', 'Success',`Pack updated successfully`);
          if (addPromises.length > 0) {
            setTimeout(() => window.location.reload(), 1000);
          }
        })
        .catch((error) => {
          openNotificationWithIcon('error', 'Error', `Pack updated with error: ${error}`);
          console.error(error);
        })
        .finally(() => {this.setState({ updateAssetsButtonDisabled: false }) });
    }
  };

  // delete assets from server
  onDeleteAssets () {
    const { illustrationIndexes, illustrationIndexesCurrent } = this.state.serverAssetsData;
    const difference = findDifferences(illustrationIndexes, illustrationIndexesCurrent);
    const allRequests = [];

    if (difference.length >= 1) {

      for (let i = 0; i < difference.length; i++) {
        this.setState(
          prevState => {
            let requestCounter = Object.assign({}, prevState.requestCounter);
            requestCounter.activate = true;
            requestCounter.push = this.state.requestCounter.push + 1;

            return { requestCounter };
          }
        );
        allRequests.push(
          axios.delete(`/api/illustration/${difference[i]}`)
            .then(response => {
              if (response.data.success === true) {

                this.setState(
                  prevState => {
                    let requestCounter = Object.assign({}, prevState.requestCounter);
                    requestCounter.complete = this.state.requestCounter.complete + 1;

                    return { requestCounter };
                  }
                );
              } else {
                openNotificationWithIcon('error', 'Error', response.data.message);
                return Promise.reject(response.data.message);
              }
            })
            .catch(function (error) {
              console.error(error);
              return Promise.reject(error);
            })
        );
      }
    }
    return allRequests;
  }

  //update assets from server information
  onUpdateAssets () {
    const {
      changedElements, illustrationsNames, illustrationsDescriptions,
      illustrationsKeywords, illustrationsCategories, illustrationsFreeOptions,
      data
    } = this.state.serverAssetsData;
    const allRequests = [];

    if (changedElements.length >= 1) {

      for (let i = 0; i < changedElements.length; i++) {
        const illustrationId = data[changedElements[i]].id,
        illustrationName = illustrationsNames[changedElements[i]],
        illustrationDescription = illustrationsDescriptions[changedElements[i]].trim(),
        illustrationFreeOption = illustrationsFreeOptions[changedElements[i]];

        let illustrationKeywords = illustrationsKeywords[changedElements[i]],
        illustrationCategoryItem = illustrationsCategories[changedElements[i]];

        illustrationKeywords = illustrationKeywords.split(',');
        let trimmedIllustrationKeywords = [];
        for (let keyword of illustrationKeywords) {
          keyword = keyword.trim();
          if (keyword.length > 0) {
            trimmedIllustrationKeywords.push(keyword);
          }
        }

        this.setState(
          prevState => {
            let requestCounter = Object.assign({}, prevState.requestCounter);
            requestCounter.activate = true;
            requestCounter.push = this.state.requestCounter.push + 1;

            return { requestCounter };
          }
        );
        allRequests.push(
          axios.put(`/api/illustration/${illustrationId}`, {
            name: illustrationName,
            description: illustrationDescription,
            keywords: trimmedIllustrationKeywords,
            categoryId: illustrationCategoryItem,
            isFree: illustrationFreeOption,
          })
            .then(response => {
              if (response.data.success === true) {
                this.setState(
                  prevState => {
                    let requestCounter = Object.assign({}, prevState.requestCounter);
                    requestCounter.activate = true;
                    requestCounter.complete = this.state.requestCounter.complete + 1;

                    return { requestCounter };
                  }
                );
              } else {
                openNotificationWithIcon('error', 'Error', response.data.message);
                return Promise.reject(response.data.message);
              }
            })
            .catch(function (error) {
              console.error(error);
              return Promise.reject(error);
            })
        );
      }
    }
    return allRequests;
  }

  // add new assets to pack
  addNewAssets () {
    const { addedAssetsNames, addedAssetsDescriptions, addedAssetsList, addedAssetsKeywords, addedAssetsCategories, addedAssetsFreeOptions} = this.state;
    const allRequests = [];

    for (let i = 0; i < addedAssetsList.length; i++) {

      let formData = new FormData();
      formData.append('packId', this.props.match.params.id);
      formData.append('authorId', 1);
      formData.append('name', addedAssetsNames[i]);
      formData.append('description', addedAssetsDescriptions[i].trim());
      formData.append('categoryId', addedAssetsCategories[i]);
      formData.append('isFree', addedAssetsFreeOptions[i]);

      for (let y = 0; y < addedAssetsList[i].length; y++) {
        formData.append('originalFiles[]', addedAssetsList[i][y].originFileObj);
      }

      let keywords = addedAssetsKeywords[i] ? addedAssetsKeywords[i].split(',') : null;

      if (keywords !== null) {
        for (let y = 0; y < keywords.length; y++) {
          const keyword = keywords[y].trim();
          if (keyword.length > 0) {
            formData.append('keywords[]', keyword);
          }
        }
      }

      this.setState(
        prevState => {
          let requestCounter = Object.assign({}, prevState.requestCounter);
          requestCounter.activate = true;
          requestCounter.push = this.state.requestCounter.push + 1;

          return { requestCounter };
        }
      );

      allRequests.push(
        axios.post(`/api/illustration`, formData)
          .then(response => {
            if (response.data.success === true) {
              this.setState(
                prevState => {
                  let requestCounter = Object.assign({}, prevState.requestCounter);
                  requestCounter.activate = true;
                  requestCounter.complete = this.state.requestCounter.complete + 1;

                  return { requestCounter };
                }
              );
            } else {
              openNotificationWithIcon('error', 'Error', response.data.message);
              return Promise.reject(response.data.message);
            }
          })
          .catch(function (error) {
            console.error(error);
            return Promise.reject(error);
          })
      );
    }
    return allRequests;
  };

  // handlers for added assets' form part:
  // asset dnd handler
  handleDragAndDropChange = info => {
    this.setState(
      { assetsFileListDnD: info.fileList },
      () => this.handlePreviewAddedAssets(this.state.assetsFileListDnD))
  };

  handlePreviewAddedAssets = async filesList => {
    const length = this.state.addedAssetsNames.length;
    const newNames = [];
    const newKeywords = [];
    const newCategories = [];
    const newDescriptions = [];
    const newFreeOptions = [];

    const uniq = [...new Set(getAllNames(filesList))];
    const allIndexes = reduceAllIndexes(filesList, uniq);


    if (this.state.addedAssetsKeywords.length === 0 || this.state.addedAssetsCategories === 0) {
      this.setState({
        addedAssetsKeywords: Array(allIndexes.length).fill(''),
        addedAssetsCategories: Array(allIndexes.length).fill(1),
      })
    }

    this.setState({
      addedAssetsList: allIndexes,
      assetsFileListDnD: setFileImageGroup(filesList, uniq),
    });

    for (let index = length; index < allIndexes.length; index++) {
      newNames.push(allIndexes[index][0].name.replace(/\.[^/.]+$/, ""));
      newKeywords.push('');
      newDescriptions.push('');
      newCategories.push(1);
      newFreeOptions.push(false);
    }

    let arrayOfPreviews = [];
    let arrayOfNames = this.state.addedAssetsNames.concat(newNames);
    let arrayOfKeywords = this.state.addedAssetsKeywords.concat(newKeywords);
    let arrayOfCategories = this.state.addedAssetsCategories.concat(newCategories);
    let arrayOfDescriptions = this.state.addedAssetsDescriptions.concat(newDescriptions);
    let arrayOfFreeOptions = this.state.addedAssetsFreeOptions.concat(newFreeOptions);

    for (const item of allIndexes) {
      item[0].preview = await getBase64(item[0].originFileObj);
      arrayOfPreviews.push(item[0].preview);
    }

    this.setState({
      addedAssetsPreviews: arrayOfPreviews,
      addedAssetsNames: arrayOfNames,
      addedAssetsKeywords: arrayOfKeywords,
      addedAssetsCategories: arrayOfCategories,
      addedAssetsDescriptions: arrayOfDescriptions,
      addedAssetsFreeOptions: arrayOfFreeOptions,
    });
  };

  onChangeInNewlyAddedAsset(value, index, stateName) {
    const clone = [...this.state[stateName]];
    clone[index] = value;

    this.setState({ [stateName]: clone });
  }

  handleDeleteAddedAsset = (index) => {
    const searchIndex = this.state.assetsFileListDnD[index].imageGroup;
    let updatedFileList = this.state.assetsFileListDnD.filter(function (el) {
      return el.imageGroup !== searchIndex
    });

    this.setState({
      assetsFileListDnD: updatedFileList,
      addedAssetsList: this.cutOneElementAtIndex(this.state.addedAssetsList, index),
      addedAssetsPreviews: this.cutOneElementAtIndex(this.state.addedAssetsPreviews, index),
      addedAssetsNames: this.cutOneElementAtIndex(this.state.addedAssetsNames, index),
      addedAssetsDescriptions: this.cutOneElementAtIndex(this.state.addedAssetsDescriptions, index),
      addedAssetsKeywords: this.cutOneElementAtIndex(this.state.addedAssetsKeywords, index),
      addedAssetsCategories: this.cutOneElementAtIndex(this.state.addedAssetsCategories, index),
      addedAssetsFreeOptions: this.cutOneElementAtIndex(this.state.addedAssetsFreeOptions, index),
    })
  };

  // handlers for loaded assets from server form part:
  handlePreviewServerAssets = serverAssetsData => {
    const assetsData = serverAssetsData.data;
    const namesLength = serverAssetsData.illustrationsNames.length;
    const newNames = [];
    const categories = [];
    const indexes = [];
    const freeOptions = [];
    const newKeywords = [];
    const newDescriptions = [];

    for (let index = 0; index < assetsData.length; index++) {
      indexes.push(assetsData[index].id);
    }

    for (let index = namesLength; index < assetsData.length; index++) {
      freeOptions.push(assetsData[index].isFree);
      newNames.push(assetsData[index].name);
      categories.push(assetsData[index].category.id);

      let keywords = '';
      if (assetsData[index].keywords) {
        keywords = assetsData[index].keywords.join(', ');
      }
      if(keywords.charAt(0) === ',') {
        keywords = keywords.replace(/^,/,'')
      }
      newKeywords.push(keywords);

      if (assetsData[index].description) {
        newDescriptions.push(assetsData[index].description);
      } else {
        newDescriptions.push('');
      }
    }

    let arrayOfNames = this.state.serverAssetsData.illustrationsNames.concat(newNames);
    let arrayOfKeywords = this.state.serverAssetsData.illustrationsKeywords.concat(newKeywords);
    let arrayOfCategories = this.state.serverAssetsData.illustrationsCategories.concat(categories);
    let arrayOfDescriptions = this.state.serverAssetsData.illustrationsDescriptions.concat(newDescriptions);
    let arrayOfFreeOptions = this.state.serverAssetsData.illustrationsFreeOptions.concat(freeOptions);

    this.setState(
      prevState => {
        let serverAssetsData = Object.assign({}, prevState.serverAssetsData);
        serverAssetsData.illustrationsNames = arrayOfNames;
        serverAssetsData.illustrationsKeywords = arrayOfKeywords;
        serverAssetsData.illustrationIndexes = indexes;
        serverAssetsData.illustrationsCategories = arrayOfCategories;
        serverAssetsData.illustrationIndexesCurrent = indexes;
        serverAssetsData.illustrationsDescriptions = arrayOfDescriptions;
        serverAssetsData.illustrationsFreeOptions = arrayOfFreeOptions;

        return {
          serverAssetsData,
        };
      }
    );
  };

  addToChangedElements(value) {
    let array = [...new Set(this.state.serverAssetsData.changedElements.concat(value))];

    this.setState(
      prevState => {
        let serverAssetsData = Object.assign({}, prevState.serverAssetsData);
        serverAssetsData.changedElements = array;
        return { serverAssetsData };
      }
    );
  }

  onChangeInLoadedAsset(value, index, stateName) {
    const clone = [...this.state.serverAssetsData[stateName]];
    clone[index] = value;

    this.setState(
      prevState => {
        let serverAssetsData = Object.assign({}, prevState.serverAssetsData);
        serverAssetsData[stateName] = clone;

        return { serverAssetsData };
      }, () => this.addToChangedElements(index)
    );
  }

  handleDeleteServerIllustration = (index) => {
    this.setState(
      prevState => {
        let serverAssetsData = Object.assign({}, prevState.serverAssetsData);

        serverAssetsData.data = this.cutOneElementAtIndex(serverAssetsData.data, index);
        serverAssetsData.illustrationsNames = this.cutOneElementAtIndex(serverAssetsData.illustrationsNames, index);
        serverAssetsData.illustrationsDescriptions = this.cutOneElementAtIndex(serverAssetsData.illustrationsDescriptions, index);
        serverAssetsData.illustrationsKeywords = this.cutOneElementAtIndex(serverAssetsData.illustrationsKeywords, index);
        serverAssetsData.illustrationsCategories = this.cutOneElementAtIndex(serverAssetsData.illustrationsCategories, index);
        serverAssetsData.illustrationIndexesCurrent = this.cutOneElementAtIndex(serverAssetsData.illustrationIndexesCurrent, index);
        serverAssetsData.illustrationsFreeOptions = this.cutOneElementAtIndex(serverAssetsData.illustrationsFreeOptions, index);

        return { serverAssetsData };
      }
    );
  };
  // <-- end of form handlers

  changeEditPackTab(evt) {
    if (evt.target.innerHTML.toLowerCase() === "information") {
      this.setState({ activeFilter: "information" });
    } else if (evt.target.innerHTML.toLowerCase() === "assets") {
      this.setState({ activeFilter: "assets" });
    }
  }

  // start requests -->
  getListsForFormSelects = async () => {
    const categories = await axios.get(`/api/category`);
    const moods = await axios.get(`/api/mood`);
    const types = await axios.get(`/api/type`);

    axios.all([categories, moods, types])
      .then(
        axios.spread((...responses) => {
          this.setState({
            categories: responses[0].data.result.categoriesData,
            moods: responses[1].data.result.moodsData,
            types: responses[2].data.result.typesData,
          })
        })
      )
      .catch(errors => { console.error(errors) })
  };

  getPackInfo() {
    axios
      .get(`/api/pack/${this.props.match.params.id}`)
      .then(response => {

        const dataObject = response.data.result.packData;

        if (dataObject === null) {
          this.setState({ error: true });
        }
        if (response.data.success && (dataObject !== null)) {
          const { name, landingUrl, figmaPreview, craftworkId, cover,
            bigCover, categories, mood, type, keywords, id, status,
            description, shortDescription, highlights, demoFileUrl
          } = response.data.result.packData;

          this.setState(prevState => {
            let packInfo = Object.assign({}, prevState.packInfo);
            packInfo.id = id;
            packInfo.name = name;
            packInfo.description = description;
            packInfo.shortDescription = shortDescription;
            packInfo.status = status;
            packInfo.cover = cover;
            packInfo.bigCover = bigCover;
            packInfo.mood = mood.id;
            packInfo.cover = cover;
            packInfo.type = type.id;
            packInfo.categories = packInfo.categories.concat(getAllElements(categories ? categories : []));
            packInfo.keywords = keywords ? keywords.join(', ') : '';

            let loadedDemoFile = []
            if (demoFileUrl) {
              let name = demoFileUrl.substring(demoFileUrl.lastIndexOf("/") + 1);
              loadedDemoFile = [{id: 1, name, status: 'done', url: demoFileUrl}]
            }

            return {
              packInfo,
              packCoverFileList: [{
                name: packInfo.name,
                status: 'done',
                preview: cover,
              }],
              packBigCoverFileList: [{
                name: packInfo.name,
                status: 'done',
                preview: bigCover,
              }],
              isPackCoverLoaded: true, isPackBigCoverLoaded: true,
              landingUrl, figmaPreview, craftworkId, highlights: highlights ?? [],
              demoFile: loadedDemoFile,
              demoFileStatus: demoFileUrl ? 'loaded': 'empty',
            };
          });
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
        }
      })
      .catch(function (error) { console.error(error) });
  }

  getAllPackIllustrations() {
    axios
      .get(`/api/illustration?packId=${this.props.match.params.id}&page=${this.state.pagination.params.currentPage}`)
      .then(response => {
        const dataObject = response.data.result.illustrationData;

        if (dataObject === null) {
          this.setState({ error: true });
        }
        if (response.data.success && (dataObject !== null)) {
          /** @param { data: { result: { illustrationData: object } } } response */
          const data = response.data.result.illustrationData;
          const { currentPage, allPage, allCount } = response.data.result.pagination;
          this.setState(
            prevState => {
              let serverAssetsData = Object.assign({}, prevState.serverAssetsData);
              serverAssetsData.data = data;
              serverAssetsData.illustrationsNames = [];
              serverAssetsData.illustrationsDescriptions = [];
              serverAssetsData.illustrationsKeywords = [];
              serverAssetsData.illustrationsCategories = [];
              serverAssetsData.illustrationsFreeOptions = [];
              serverAssetsData.changedElements = [];

              return {
                serverAssetsData,
                addedAssetsList: [],
                assetsFileListDnD: [],
                addedAssetsNames: [],
                addedAssetsDescriptions: [],
                addedAssetsKeywords: [],
                addedAssetsFreeOptions: [],
              };
            }, () => this.handlePreviewServerAssets(this.state.serverAssetsData)
          );
          this.setState(prevState =>({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              params: {
                ...prevState.pagination.params,
                allPage: allPage,
                currentPage: currentPage,
                allCount: allCount
              }
            }
          }))
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
        }
      })
      .catch(function (error) { console.error(error);});
  }

  componentDidMount() {
    this.getListsForFormSelects();
    this.getPackInfo();
    this.getAllPackIllustrations();
  }

  onChangePagination(page) {
    this.setState(
      prevState => {
        let pagination = Object.assign({}, prevState.pagination);
        pagination.params.currentPage = page;
        return { pagination };
      },
      () => this.getAllPackIllustrations()
    );
  }

  render() {
    const {
      packCoverFileList, packBigCoverFileList, addedAssetsList,
      isPackCoverLoaded, isPackBigCoverLoaded, packInfo,
      error, serverAssetsData , activeFilter,
      updateAssetsButtonDisabled, addedAssetsCategories,
      presentationFileList, landingUrl, figmaPreview,
      craftworkId, highlights, demoFile
    } = this.state;
    return (
      <div className="pack-edit">
        {error === true ? <Redirect to="/404" /> : null}
        <Header />
        <div className="container-custom">
          <div className="row content-row">
            <div className="col-lg-2 col-md-3">
              <NavSidebar />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-lg-3 button-column">
                <Link to={{ pathname: '/packs'}} >
                  <ButtonIcon class="neutral outline big">
                    <span>
                      <IconEscape />
                    </span>
                    <span>Packs</span>
                  </ButtonIcon>
                </Link>
                </div>
                <div className="col-lg-6">
                  <h3>Editing pack : <br></br> {packInfo.name ? packInfo.name : '...'}</h3>
                  <CustomTabs
                      array={[`Information`, `Assets`]}
                      updateData={this.changeEditPackTab}
                      current={this.state.activeFilter}
                  />
                </div>
                {activeFilter === "information" &&
                  <div className="col-lg-6 offset-lg-3">
                    <Form
                        ref = { this.editPackFormRef }
                        name='editPackFrom'
                        className='pack-form'
                        layout='vertical'
                        onFinish={this.submitPackInformationForm}
                        initialValues={{
                          name: packInfo.name,
                          landingUrl: landingUrl,
                          figmaPreview: figmaPreview,
                          userName: 'Craftwork',
                          category: packInfo.categories,
                          keywords: packInfo.keywords,
                          description: (packInfo.description === 'undefined'? '' : packInfo.description),
                          shortDescription: (packInfo.shortDescription === 'undefined'? '' : packInfo.shortDescription),
                          type: packInfo.type ?? '',
                          mood: packInfo.mood ?? '',
                          craftworkId: craftworkId ?? '',
                        }}
                    >
                      <Form.Item
                        label='Name'
                        name='packName'
                        onChange={(event) => this.handleSubStateChange('packInfo', 'name', event)}
                      >
                        <Input value={packInfo.name} />
                        <p className='hidden-text-element'>{packInfo.name}</p>
                      </Form.Item>
                      <Form.Item
                        label='Landing Url'
                        name='landingUrl'
                        rules={[{type: 'url', message: 'This field must be a valid url.'}]}
                        onChange={(event) => this.handleStateChange(event, 'landingUrl')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label='Figma preview'
                        name='figmaPreview'
                        rules={[{type: 'url', message: 'This field must be a valid url.'}]}
                        onChange={(event) => this.handleStateChange(event, 'figmaPreview')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label='Craftwork ID'
                        name='craftworkId'
                        value={this.state.craftworkId || ''}
                        onChange={(event) => this.handleInputForNumberChange(event, 'craftworkId')}
                      >
                        <Input />
                      </Form.Item>
                      <Form.List name='highlights' initialValue={highlights}>
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map((field, index) => (
                              <Form.Item
                                label={index === 0 ? 'Highlights' : ''}
                                required={false}
                                key={field.key}
                                onChange={(event) => this.handleFormListItemChange(event, index)}
                              >
                                <Form.Item
                                  className={`form-list-item ${field.name}`}
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input highlight text",
                                    },
                                  ]}
                                >
                                  <Input placeholder="highlight"/>
                                </Form.Item>
                                {fields.length > 0 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button absolute-button"
                                    onClick={() => {
                                      remove(field.name);
                                      this.handleFormListItemDelete(field.name);
                                    }}
                                  />
                                ) : null}
                              </Form.Item>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}>
                                Add highlight
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <Form.Item
                        label='Description'
                        name='description'
                      >
                        <Input.TextArea
                          maxLength={160}
                          value={packInfo.description}
                          autoSize={{ minRows: 4 }}
                          onChange={(event) => this.handleSubStateChange('packInfo', 'description', event)}
                        />
                      </Form.Item>
                      <Form.Item
                        label='Short description'
                        name='shortDescription'
                      >
                        <Input.TextArea
                          maxLength={80}
                          autoSize={{ minRows: 3, maxRows: 4 }}
                          onChange={(event) => this.handleStateChange(event, 'shortDescription')}
                        />
                      </Form.Item>
                      <Form.Item
                        label='Status'
                        name='status'
                      >
                        <p className='hidden-text-element'>{status[0].id}</p>
                        <Select
                          onChange={(event) => this.handleSubStateChange('packInfo', 'status', event, event)}
                          value={status[packInfo.status - 1].id}
                        >
                          {status.map((stat) =>
                              <Select.Option key={stat.id + stat.name} value={stat.id}>{stat.name}</Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="container"
                        label='Cover'
                        className='pack-cover__container'
                      >
                        {isPackCoverLoaded === false &&
                        <Upload
                          listType='picture-card'
                          className='images-uploader'
                          fileList={packCoverFileList}
                          beforeUpload={() => false}
                          onChange={(info) => this.handleUploadPackCoverType(info,'PackCover')}
                        >
                          <div className={`ant-upload-text d-flex flex-column align-items-center justify-content-center`}>
                            <div className='images-uploader__add-block'>
                              <div className='images-uploader__add-icon'>
                                <IconPlus fill="#000000" />
                              </div>
                              Add image
                            </div>
                            <p className="ant-upload-hint">Png, 240×240px</p>
                          </div>
                        </Upload>
                        }
                        {isPackCoverLoaded === true &&
                        <div className="cover-card">
                          <div className="image-wrapper">
                            <img width={80} height={80} src={packCoverFileList[0].preview} alt="pack cover" />
                          </div>
                          <div>
                            <p className="title">{packCoverFileList[0].name}</p>
                            <p className="sizes">240×240px</p>
                          </div>
                          <Button type="link" onClick={() => this.onCoverCancel('packCover')} icon={<IconCart fill="#CDCDCD" />} />
                        </div>
                        }
                      </Form.Item>
                      <Form.Item
                        label='Big cover'
                        className='pack-cover__container'
                      >
                        {isPackBigCoverLoaded === false &&
                        <Upload
                          listType='picture-card'
                          className='avatar-uploader'
                          fileList={packBigCoverFileList}
                          beforeUpload={() => false}
                          onChange={(info) => this.handleUploadPackCoverType(info,'packBigCover')}
                        >
                          <div className={`ant-upload-text d-flex flex-column align-items-center justify-content-center`}>
                            <div style={{ display: 'flex' }}>
                              <div style={{ marginRight: "5px" }}>
                                <IconPlus fill="#000000" />
                              </div>
                              Add image
                            </div>
                            <p className="ant-upload-hint">Png, 1600х1200px</p>
                          </div>
                        </Upload>
                        }
                        {isPackBigCoverLoaded === true &&
                          <div className="cover-card">
                            <div className="image-wrapper">
                              <img width={80} height={80} src={packBigCoverFileList[0].preview} alt="pack cover" />
                            </div>
                            <div>
                              <p className="title">{packBigCoverFileList[0].name}</p>
                              <p className="sizes">240×240px</p>
                            </div>
                            <Button type="link" onClick={() => this.onCoverCancel('packBigCover')} icon={<IconCart fill="#CDCDCD" />} />
                          </div>
                        }
                      </Form.Item>
                      {/*Blocked for now - need new backend realisation for add that*/}
                      {/*<Form.Item*/}
                      {/*  label='Presentation images'*/}
                      {/*  className='pack-cover__container'*/}
                      {/*>*/}
                      {/*  <Upload*/}
                      {/*    listType='picture-card'*/}
                      {/*    className='ant-picture-wall-uploader ant-upload-no-preview '*/}
                      {/*    fileList={presentationFileList}*/}
                      {/*    multiple={true}*/}
                      {/*    beforeUpload={() => false}*/}
                      {/*    onChange={this.handlePresentationUpload}*/}
                      {/*  >*/}
                      {/*    <div><PlusOutlined /><div style={{ marginTop: 8 }}>Upload</div></div>*/}
                      {/*  </Upload>*/}
                      {/*</Form.Item>*/}
                      <Form.Item
                        label='Author username'
                        name='userName'
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        label='Type'
                        name='type'
                        rules={[{ required: true, message: 'Please input pack type!' }]}
                      >
                        <Select
                          onChange={(event) => this.handleSubStateChange('packInfo', 'type', event, event)}
                          value={packInfo.type !== undefined && packInfo.type ? packInfo.type.id: ''}>
                            {this.state.types.map((type) =>
                                <Select.Option key={type.id + type.name} value={type.id}>{type.name}</Select.Option>)
                            }
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label='Mood'
                        name='mood'
                        rules={[{ required: true, message: 'Please input pack mood!' }]}
                      >
                        <Select
                          onChange={(event) => this.handleSubStateChange('packInfo', 'mood', event, event)}
                          value={packInfo.mood !== undefined && packInfo.mood ? packInfo.mood.id: ''}>
                            {this.state.moods.map((mood) =>
                              <Select.Option key={mood.id + mood.name} value={mood.id}>{mood.name}</Select.Option>)
                            }
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label='Keywords'
                        name='keywords'
                      >
                        <Input.TextArea
                          value={packInfo.keywords}
                          autoSize={{ minRows: 4 }}
                          onChange={(event) => this.handleSubStateChange('packInfo', 'keywords', event)}
                        />

                      </Form.Item>
                      <Form.Item label="Demo Files" name='demoFile' className='show-text-list'>
                        <Upload
                          listType='text'
                          beforeUpload={() => false}
                          onChange={(info)=> this.handleFileChangeOnEdit(info, 'demoFile')}
                          fileList={demoFile}
                        >
                          <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                      </Form.Item>
                      <Form.Item name="submit">
                        <div className="button-wrapper">
                          <Button type="primary" disabled={!isPackCoverLoaded || !isPackBigCoverLoaded} htmlType='submit'>
                            Update Pack
                          </Button>

                          <Popconfirm title="Are you sure？" onConfirm={this.deletePack} icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                            <Button danger type="text"> Delete Pack</Button>
                          </Popconfirm>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                }
                {activeFilter === "assets" &&
                  <div className="col-lg-6  offset-lg-3 left-column">
                    <Form
                      name='createIllustrationFrom'
                      className='pack-form-custom'
                      layout='vertical'
                      onFinish={this.submitAssetsForm}
                    >
                      <Form.Item name="dragger">
                        <Dragger
                          className='images-uploader'
                          beforeUpload={() => false}
                          multiple={true}
                          onChange={this.handleDragAndDropChange}
                          fileList={this.state.assetsFileListDnD}
                        >
                          <div className={`ant-upload-text d-flex align-items-center justify-content-center`}>
                            <div className='images-uploader__add-icon'>
                              <IconPlus fill="#000000" />
                            </div>
                            Add images
                          </div>
                          <p className="ant-upload-hint">or drag and drop files here</p>
                        </Dragger>
                        <p className="hint">If an asset have more than one format (.svg and .png), make sure all of them called the same name to be assigned to the same asset. <br/> Example: Story.png, Story.svg.</p>
                      </Form.Item>
                      {/* array of new assets */}
                      {addedAssetsList.length >= 1 &&
                        <>
                          <h2 className='pack-form__title'>Add New</h2>
                          <Form.Item name="counter">
                            <p className="counter">{addedAssetsList.length} assets</p>
                          </Form.Item>
                        </>
                      }
                      {addedAssetsList.map((field, index) => (
                        <div className="pack-form__asset" key={field.id}>
                          <div>
                            <Form.Item name="tags">
                              <div className="pack-form__image-container">
                                <div className="pack-form__tags">
                                  {field.map((item, index) => (
                                    <Tag color="#ffffff" key={index}>{item.name.slice(item.name.lastIndexOf('.'))}</Tag>
                                  ))}
                                </div>
                                {(addedAssetsList.length > 0) &&
                                  <img
                                    width={123}
                                    height={123}
                                    src={this.state.addedAssetsPreviews[index]} alt={`newitem-${index + 1}`}
                                  />
                                }
                              </div>
                            </Form.Item>
                            <Form.Item name={[field[0].uid, 'addedFreeOption']} className="pack-form__switch">
                              <Switch
                                id={"free-switch-" + field[0].uid}
                                defaultChecked={false}
                                checked={this.state.addedAssetsFreeOptions[index]}
                                onChange={value => this.onChangeInNewlyAddedAsset(value, index, 'addedAssetsFreeOptions')}
                              />
                              <label htmlFor={"free-switch-" + field[0].uid}>Free</label>
                            </Form.Item>
                          </div>
                          <Row gutter={24} className='pack-form__row'>
                            <Col lg={12} md={12} sm={24} xs={24} className='pack-form__col'>
                              <Form.Item
                                name={[index, 'addedIllustrationName']}
                                fieldKey={[field.fieldKey, 'addedIllustrationName']}
                              >
                                <Input id={field.fieldKey} placeholder={"Name"} value={this.state.addedAssetsNames[index]} onChange={event => this.onChangeInNewlyAddedAsset(event.target.value, index, 'addedAssetsNames')} />
                                <p className='hidden-text-element'>{this.state.addedAssetsNames[index]}</p>
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24} className='pack-form__col'>
                              <Form.Item
                                name='addedIllustrationType'
                              >
                                <Select
                                  onChange={(value) => this.onChangeInNewlyAddedAsset(value, index, 'addedAssetsCategories')}
                                  value={addedAssetsCategories !== undefined && addedAssetsCategories ? addedAssetsCategories[index] : ''}
                                >
                                  {this.state.categories.map((type) => <Select.Option key={type.id + type.name} value={type.id}>{type.name}</Select.Option>)}
                                </Select>
                                <p className='hidden-text-element'>{serverAssetsData.illustrationsCategories[index]}</p>
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24} className='pack-form__col'>
                              <Form.Item
                                name={[index, 'addedIllustrationDescription']}
                                fieldKey={[field.fieldKey, 'addedIllustrationDescription']}
                              >
                                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} maxLength={1000} placeholder={"Description"} value={this.state.addedAssetsDescriptions[index]} onChange={(event) => this.onChangeInNewlyAddedAsset(event.target.value, index, 'addedAssetsDescriptions')} id={field.fieldKey} />
                                <p className='hidden-text-element'>{this.state.addedAssetsDescriptions[index]}</p>
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24} className='pack-form__col'>
                              <Form.Item
                                name={[index, 'addedillustrationsKeywords']}
                                fieldKey={[field.fieldKey, 'addedillustrationsKeywords']}
                              >
                                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} placeholder={"Keywords"} value={this.state.addedAssetsKeywords[index]} onChange={(event) => this.onChangeInNewlyAddedAsset(event.target.value, index, 'addedAssetsKeywords')} id={field.fieldKey} />
                                <p className='hidden-text-element'>{this.state.addedAssetsKeywords[index]}</p>
                              </Form.Item>
                            </Col>
                          </Row>
                          <div className='pack-form__delete-button'>
                            <Button type="link" onClick={() => this.handleDeleteAddedAsset(index)} icon={<IconCart fill="#CDCDCD" />}/>
                          </div>
                        </div>
                      ))}
                      <Form.Item name="submit">
                        <Button htmlType='submit' disabled={updateAssetsButtonDisabled}>
                          Update Pack
                          </Button>
                      </Form.Item>
                      <Pagination
                         defaultCurrent={1}
                         defaultPageSize={20}
                         current={this.state.pagination.params.currentPage}
                         total={this.state.pagination.params.allCount}
                         onChange={this.onChangePagination}
                      />
                      {/* an array of already loaded assets */}
                      {serverAssetsData.data.length >= 1 &&
                        <>
                          <h2 className='pack-form__title'>Already loaded assets</h2>
                          <Form.Item name="counter">
                            <p className="counter">{serverAssetsData.data.length} {serverAssetsData.data.length === 1? 'asset': 'assets'}</p>
                          </Form.Item>
                        </>
                      }

                      {serverAssetsData.data.map((field, index) => (
                          <div className="pack-form__asset" key={field.id}>
                            <div>
                              <Form.Item name="tags">
                                <div className="pack-form__image-container">
                                  <div className="pack-form__tags">
                                    {field.assignedExtensions && field.assignedExtensions.map((item, index) => (
                                      <Tag color="#ffffff" key={index}>.{item}</Tag>
                                    ))}
                                  </div>
                                  {(field.cover) &&
                                  <img width={123} height={123} src={field.cover} alt={field.name}/>
                                  }
                                </div>
                              </Form.Item>
                              <Form.Item name={[field.id, 'illustrationFreeOption']} className="pack-form__switch">
                                <Switch
                                  id={"free-switch-" + field.id}
                                  defaultChecked={field.isFree}
                                  checked={this.state.serverAssetsData.illustrationsFreeOptions[index]}
                                  onChange={value => this.onChangeInLoadedAsset(value, index, 'illustrationsFreeOptions')}
                                />
                                <label htmlFor={"free-switch-" + field.id}>Free</label>
                              </Form.Item>
                            </div>
                            <Row gutter={24} className='pack-form__row'>
                              <Col lg={12} md={12} sm={24} xs={24} className='pack-form__col'>
                                <Form.Item
                                  name={[index, 'illustrationName']}
                                  fieldKey={[field.fieldKey, 'illustrationName']}
                                >
                                  <Input
                                    id={field.fieldKey}
                                    placeholder={"Name"}
                                    value={this.state.serverAssetsData.illustrationsNames[index]}
                                    onChange={event => this.onChangeInLoadedAsset(event.target.value, index, 'illustrationsNames')} />
                                  <p className='hidden-text-element'>{this.state.serverAssetsData.illustrationsNames[index]}</p>
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} sm={24} xs={24} className='pack-form__col'>
                                <Form.Item
                                  name='elementType'
                                >
                                  <Select
                                    onChange={(value) => this.onChangeInLoadedAsset(value, index, 'illustrationsCategories')}
                                    value={serverAssetsData.illustrationsCategories !== undefined ? serverAssetsData.illustrationsCategories[index] : ''}
                                  >
                                    {this.state.categories.map((type) => <Select.Option key={type.id + type.name} value={type.id}>{type.name}</Select.Option>)}
                                  </Select>
                                  <p className='hidden-text-element'>{serverAssetsData.illustrationsCategories[index]}</p>
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} sm={24} xs={24} className='pack-form__col'>
                                <Form.Item
                                  name={[index, 'illustrationDescription']}
                                  fieldKey={[field.fieldKey, 'illustrationDescription']}
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    maxLength={1000}
                                    placeholder={"Description"}
                                    value={this.state.serverAssetsData.illustrationsDescriptions[index]}
                                    onChange={(event) => this.onChangeInLoadedAsset(event.target.value, index, 'illustrationsDescriptions')} id={field.fieldKey} />
                                  <p className='hidden-text-element'>{this.state.serverAssetsData.illustrationsDescriptions[index]}</p>
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} sm={24} xs={24} className='pack-form__col'>
                                <Form.Item
                                  name={[index, 'illustrationsKeywords']}
                                  fieldKey={[field.fieldKey, 'illustrationsKeywords']}
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    placeholder={"Keywords"}
                                    value={this.state.serverAssetsData.illustrationsKeywords[index]}
                                    onChange={(event) => this.onChangeInLoadedAsset(event.target.value, index, 'illustrationsKeywords')} id={field.fieldKey} />
                                  <p className='hidden-text-element'>{this.state.serverAssetsData.illustrationsKeywords[index]}</p>
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className='pack-form__delete-button'>
                              <Button type="link" onClick={() => this.handleDeleteServerIllustration(index)} icon={<IconCart fill="#CDCDCD" />}/>
                            </div>
                          </div>
                      ))}
                    </Form>
                  </div>
                  }
              </div>
            </div>
          </div>
        </div>
        <Footer bordered={true} />
      </div>
    )
  }
}

const PackEditRouter = withRouter(PackEdit);
export default PackEditRouter;
