import axios from 'axios';

export const setAutorizedAction = () => {
  localStorage.setItem('storyAdminUser', true);
  return {
    type: 'SET_AUTHORIZED',
    payload: true
  }
};

export const setUnAutorizedAction = () => {
  localStorage.setItem('storyAdminUser', false);
  return {
    type: 'SET_UNAUTHORIZED',
    payload: false
  }
};

export const authorizeAction = () => dispatch => {
  axios.get(`/api/userInfo/validateSession`)
  .then(res => {
    dispatch(setAutorizedAction())
    if (res.status === 200) {
      if (res.data.success) {
        dispatch(setUserData(res.data.result));
      } else {
        throw new Error();
      }
    }
  })
  .catch( error => {
    dispatch(setUnAutorizedAction());
    dispatch(clearUserData());
  }) 
}

export const setUserData = (data) => {
  localStorage.setItem('storyAdminUserName', data.user.name);
  return {
    type: 'SET_USER_DATA',
    payload: {
      name: data.user.name,
      email: data.email
    }
  }  
};

export const clearUserData = () => {
  localStorage.setItem('storyAdminUserName', null);
  return {
    type: 'CLEAR_USER_DATA',
    payload: {
      name: null,
      email: null
    }
  }  
};
