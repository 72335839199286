import React, { Component } from 'react';
import NavSidebar from '../../components/NavSidebar';
import FlexContainer from '../../components/FlexContainer';
import FlexContainerItem from '../../components/FlexContainerItem';
import StatisticCard from '../../components/StatisticCard';
import PeriodTabs from '../../components/PeriodTabs';
import PeriodTabsItem from '../../components/PeriodTabsItem';
import Calendar from '../../components/Calendar';
import Footer from "../../components/Footer";
import Header from "../../components/Header";

// Third party components/libs
import { DatePicker } from 'antd';
import { openNotificationWithIcon } from "../../lib/notifications"
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment-timezone';
import axios from 'axios';

//Styles
import '../../Dashboard.css';

class Statistic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previous: {
        params: {
          from: moment()
            .subtract(2, "month")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
        },
        data: []
      },
      current: {
        params: {
          from: moment()
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        },
        data: []
      },
      datePickerValueFrom: moment()
        .subtract(2, "month")
        .format("YYYY-MM-DD"),
      datePickerValueTo: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      singlePeriod: false,
      duringDays: '',
      fixedPeriod: 'Month'
    };

    this.changeRequest = this.changeRequest.bind(this);
    this.getStatistic = this.getStatistic.bind(this);
    this.calendarOnChange = this.calendarOnChange.bind(this);
  }

  componentDidMount() {
    this.getStatistic(this.state.previous.params, 'previous');
    this.getStatistic(this.state.current.params, 'current');

    this.countDays(this.state.datePickerValueFrom, this.state.datePickerValueTo);
  }

  countDays(start, end) {
    this.setState({
      duringDays: moment.duration(moment(end).diff(moment(start))).asDays(),
    })
  }

  getStatistic(requestParams, targetStat) {
    axios.get(`/api/statistic/getSummaryStatistic`, { params: requestParams }, { timeout: 3000 })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            [targetStat]: {
              params: requestParams,
              data: response.data.result.summaryStatistic,
            }
          })
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
          console.log(this);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeRequest(value) {
    this.setState({
      singlePeriod: false,
    });

    var prevFrom = this.state.previous.from;
    var prevTo = this.state.previous.to;
    var curFrom = this.state.current.from;
    var curTo = this.state.current.to;

    switch (value) {
      case 'Day':
        prevFrom = moment().subtract(1, "days").format("YYYY-MM-DD");
        prevTo = moment().subtract(1, "days").format("YYYY-MM-DD");
        curFrom = moment().format("YYYY-MM-DD");
        curTo = moment().format("YYYY-MM-DD");
        this.setState({
          fixedPeriod: 'Day'
        })
        break;
      case 'Week':
        prevFrom = moment().subtract(2, 'week').format('YYYY-MM-DD')
        prevTo = moment().subtract(1, 'week').format('YYYY-MM-DD')
        curFrom = moment().subtract(1, 'week').format('YYYY-MM-DD')
        curTo = moment().format("YYYY-MM-DD");
        this.setState({
          fixedPeriod: 'Week'
        })
        break;
      case 'Month':
        prevFrom = moment().subtract(2, 'month').format('YYYY-MM-DD')
        prevTo = moment().subtract(1, 'month').format('YYYY-MM-DD')
        curFrom = moment().subtract(1, 'month').format('YYYY-MM-DD')
        curTo = moment().format("YYYY-MM-DD");
        this.setState({
          fixedPeriod: 'Month'
        })
        break;
      case 'Year':
        prevFrom = moment().subtract(2, 'year').format('YYYY-MM-DD')
        prevTo = moment().subtract(1, 'year').format('YYYY-MM-DD')
        curFrom = moment().subtract(1, 'year').format('YYYY-MM-DD')
        curTo = moment().format("YYYY-MM-DD");
        this.setState({
          fixedPeriod: 'Year'
        })
        break;
      default:
        prevFrom = moment().subtract(2, 'month').format('YYYY-MM-DD')
        prevTo = moment().subtract(1, 'month').format('YYYY-MM-DD')
        curFrom = moment().subtract(1, 'month').format('YYYY-MM-DD')
        curTo = moment().format("YYYY-MM-DD");
        this.setState({
          fixedPeriod: 'Month'
        })
    }

    this.setState(prevState => {
      let current = Object.assign({}, prevState.current);
      current.params.from = curFrom;
      current.params.to = curTo;

      return { current };
    }, () => {
      this.getStatistic(this.state.current.params, 'current');
      this.setState({
        datePickerValueFrom: curFrom,
        datePickerValueTo: curTo,
      });
    });
    this.setState(prevState => {
      let previous = Object.assign({}, prevState.previous);
      previous.params.from = prevFrom;
      previous.params.to = prevTo;

      return { previous };
    }, () => {
      this.getStatistic(this.state.previous.params, 'previous');
    });
  }

  calendarOnChange(date, dateString, pickerInd) {
    var period = (pickerInd === 'fromPicker') ? 'datePickerValueFrom' : 'datePickerValueTo';

    this.setState({
      singlePeriod: true,
      [period]: date ? date.format('YYYY-MM-DD') : undefined,
    }, () => {
      this.countDays(this.state.datePickerValueFrom, this.state.datePickerValueTo);
      this.setState(prevState => {
        let current = Object.assign({}, prevState.current);
        current.params.from = this.state.datePickerValueFrom;
        current.params.to = this.state.datePickerValueTo;

        return { current };
      }, () => {
        this.getStatistic(this.state.current.params, 'current');
        this.setState({
          fixedPeriod: null,
        })
      });
    });
  }

  render() {
    const datePickerDateFormat = "Do MMM YY";

    return (
      <>
        <Header />
        <div className="container-custom">
          <div className="row">
            <div className="col-lg-2 col-md-3" style={{ marginTop: "70px" }}>
              <NavSidebar />
            </div>
            <div className="col" style={{ marginTop: "70px" }}>
              <h1 className='dashboard__heading'>Statistic</h1>
              <div className='dashboard__subheading'>
                <div className='dashboard__subheading-item'>
                  <PeriodTabs>
                    <PeriodTabsItem isActive={this.state.fixedPeriod === 'Day'} onClick={(value) => this.changeRequest(value)} name='Day' />
                    <PeriodTabsItem isActive={this.state.fixedPeriod === 'Week'} onClick={(value) => this.changeRequest(value)} name='Week' />
                    <PeriodTabsItem isActive={this.state.fixedPeriod === 'Month'} onClick={(value) => this.changeRequest(value)} name='Month' />
                    <PeriodTabsItem isActive={this.state.fixedPeriod === 'Year'} onClick={(value) => this.changeRequest(value)} name='Year' />
                  </PeriodTabs>
                </div>
                <div className='dashboard__subheading-item'>
                  <Calendar
                    startDatePicker={
                      <DatePicker
                        value={moment(this.state.datePickerValueFrom)}
                        defaultValue={moment(this.state.current.params.from)}
                        format={datePickerDateFormat}
                        onChange={(date, dateString) => this.calendarOnChange(date, dateString, 'fromPicker')}
                        allowClear={false}
                      />
                    }
                    endDatePicker={
                      <DatePicker
                        value={moment(this.state.datePickerValueTo)}
                        defaultValue={moment(this.state.current.params.to)}
                        format={datePickerDateFormat}
                        onChange={(date, dateString) => this.calendarOnChange(date, dateString, 'toPicker')}
                        allowClear={false}
                      />
                    }
                  />
                </div>
              </div>
              <div style={{ maxWidth: '1110px' }}>
                <FlexContainer>
                  {Object.keys(this.state.current.data).map((keyName, index) =>
                    <FlexContainerItem key={keyName + index}>
                      <StatisticCard
                        name={keyName}
                        value={this.state.current.data[keyName]}
                        prevValue={this.state.previous.data[keyName]}
                        isSingle={this.state.singlePeriod}
                        days={Math.trunc(this.state.duringDays)}
                      />
                    </FlexContainerItem>
                  )}
                </FlexContainer>
              </div>
            </div>
          </div>
        </div>

        <Footer bordered={true} />
      </>
    )
  }
}

export default Statistic;
