import React, { Component } from 'react';
import NavSidebarItem from './NavSidebarItem';

import './NavSidebar.css';

class NavSidebar extends Component {
  render() {
    return (
      <ul className='nav-sidebar'>
        <NavSidebarItem name='Statistic' link='/statistic' />
        <NavSidebarItem name='Packs' link='/packs' />
        <NavSidebarItem name='Assets' link='/assets' />
        <div className="nav-sidebar__delimiter" />
        <NavSidebarItem name='Plans' link='/plans' />
        <NavSidebarItem name='Logout' link='/logout' />
      </ul>
    );
  }
}

export default NavSidebar;
