import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers/rootReducer';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));

export default function configureStore(initialState={}) {
 return createStore(
   rootReducer,
   initialState,
   composedEnhancer
 );
}
