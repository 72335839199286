import React, { Component } from 'react';
import './CustomTabs.css';

class CustomTabs extends Component {
    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(evt) {
        evt.preventDefault();
        Array.from(evt.currentTarget.closest("ul").querySelectorAll('a')).forEach(element => element.classList.remove('active'));
        evt.currentTarget.classList.add('active');
    }

    render() {
        const { array, current } = this.props;

        return (
            <div className="custom-tabs">
                <ul className="nav nav-pills mx-auto" onClick={(evt) => this.props.updateData(evt)}>
                    {array.map((item, index) =>
                        <li
                            className="nav-item"
                            key={index}>
                            <div
                                className={`nav-link ${current === item.toLowerCase() ? `active` : null}`}
                                onClick={this.handleClick}
                            >
                                {item}
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default CustomTabs;
