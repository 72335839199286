import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import Statistic from './pages/Statistic';
import Packs from './pages/Packs';
import Plans from './pages/Plans';
import ViewPack from './pages/ViewPack';
import ViewPlan from './pages/ViewPlan';
import PlanCreate from './pages/PlanCreate';
import PackCreate from './pages/PackCreate';
import PackEditRouter from './pages/PackEdit';
import PlanEditRouter from './pages/PlanEdit';
import IllustrationsRouter from './pages/Illustrations';
import PageNotFound from './pages/404';
import ScrollToTop from './components/ScrollToTop';
import { PrivateRoute } from './components/PrivateRoute';
import Login from './pages/login';
import Logout from './pages/logout';

import ErrorBoundary from './components/ErrorBoundary';

import './App.css';

//Redux
import { connect } from 'react-redux';
import { authorizeAction } from './actions';

const mapDispatchToProps = dispatch => ({
  authorizeAction: () => dispatch(authorizeAction())
})

const mapStateToProps = state => ({ ...state })

class App extends Component {

  componentDidMount() {
    this.props.authorizeAction();
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <PrivateRoute path='/statistic' exact component={Statistic} />
            <PrivateRoute path='/packs' exact component={Packs} />
            <PrivateRoute path='/plans' exact component={Plans} />
            <PrivateRoute path='/assets' exact component={IllustrationsRouter} />
            <PrivateRoute path='/pack/create' component={PackCreate} />
            <PrivateRoute path='/plan/create' component={PlanCreate} />
            <PrivateRoute
              exact
              path='/pack/edit/:id?'
              component={PackEditRouter}
            >
            </PrivateRoute>


              <PrivateRoute
                exact
                path='/plan/:id?'
                component={ViewPlan}
              />


              <Route
                exact
                path='/pack/:id?'
                component={ViewPack}
              ></Route>

              <PrivateRoute
                  exact
                  path='/plan/edit/:id?'
                  component={PlanEditRouter}
              >
              </PrivateRoute>
              <Route path='/' exact component={() => <ErrorBoundary><Login/></ErrorBoundary>} />
              <Route path='/login' exact component={() => <ErrorBoundary><Login/></ErrorBoundary>} />
              <Route path='/logout' exact component={Logout} />
              <Route path='/404' component={() => <ErrorBoundary><PageNotFound/></ErrorBoundary>} />
              <Redirect to='/404' />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
