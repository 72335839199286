/** React imports  */
import React, { Component } from "react";

/** React router */
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

/** Third party imports */
import { Form, Input, Button, Select, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

/** App React components */
import { IconCart, IconPlus, IconEscape } from '../../components/Icons';
import NavSidebar from '../../components/NavSidebar';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ButtonIcon from "../../components/ButtonIcon";

/** App common modules */
import { openNotificationWithIcon } from "../../lib/notifications";
import { getAllNames, reduceAllIndexes, setFileImageGroup, getBase64 } from "../../utils/utils";

/** Styles */
import './index.css';

class PackCreate extends Component {
  createPackFormRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      previewImage: [],
      previewTitle: '',
      createdPackId: '',
      successPackCreated: false,
      fileList: [],
      myData: [],
      status: [
        { 'id': 1, 'name': 'Draft' },
        { 'id': 2, 'name': 'Published' },
        { 'id': 3, 'name': 'Trash' }
      ],
      moods: [],
      isCoverLoaded: false,
      isBigCoverLoaded: false,
      categories: [],
      types: [],
      packName: '',
      authorName: '',
      selectedStatus: 1,
      selectedMood: 1,
      selectedType: 1,
      selectedCategories: [],
      packKeywords: '',
      packDescription: '',
      packShortDescription: '',
      isLoading: true,
      illustrationName: '',
      illustrationKeywords: '',
      fileListDnD: [],
      inputsNames: [],
      illustrationFormList: [],
      illustrationsKeywords: [],
      createdIllustrationId: [],
      illustrationFormItems: [],
      bigCoverFileList: [],
      presentationFileList: [],
      landingUrl: '',
      figmaPreview: '',
      craftworkId: null,
      highlights: [],
      demoFile: [],
    };

    this.handleSelectValueChange = this.handleSelectValueChange.bind(this);
    this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
    this.formOnFinish = this.formOnFinish.bind(this);
  }

  handleCoverUpload = async info => {
    let fileList = [...info.fileList];
    fileList[0].preview = await getBase64(fileList[0].originFileObj);

    this.setState({
      fileList,
      isCoverLoaded: !this.state.isCoverLoaded
    });
  };

  handleBigCoverUpload = async info => {
    const bigCoverFileList = [...info.fileList];

    this.setState({
      bigCoverFileList,
      isBigCoverLoaded: !this.state.isBigCoverLoaded,
    });
  };

  /** For single file state change event
   * @param {object} info Object with information about file upload process information
   * @param {string} stateName Target state name
   * */
  handleFileChange = async (info, stateName) => {
    let loadedFile = [...info.fileList];

    this.setState({ [stateName]: loadedFile.slice(-1) })
  }

  /** Triggers when a new image loaded to images array
   * @param {object} info Object with information about files upload process information
   * @return {null}
   * */
  handlePresentationUpload = async info => {
    const presentationFileList = [...info.fileList];
    this.setState({ presentationFileList });
  };

  onChangeNameValue = (value, index) => {
    const names = this.state.inputsNames.slice(0);
    names[index] = value;

    this.setState({
      inputsNames: names,
    });
  };

  handleDragAndDropChange = info => {
    this.setState({ fileListDnD: info.fileList }, () => this.handlePreview(this.state.fileListDnD))
  };

  handlePreview = async filesList => {

    const length = this.state.inputsNames.length;
    const newNames = [];
    const newKeywords = [];

    const uniq = [...new Set(getAllNames(filesList))];
    const allIndexes = reduceAllIndexes(filesList, uniq);

    this.setState({
      illustrationFormItems: allIndexes,
      fileListDnD: setFileImageGroup(filesList, uniq),
      illustrationsKeywords: Array(allIndexes.length).fill(''),
    });

    for (let index = length; index < allIndexes.length; index++) {
      newNames.push(allIndexes[index][0].name.replace(/\.[^/.]+$/, ""));
      newKeywords.push('');
    }

    let arrayOfPreviews = [];
    let arrayOfNames = this.state.inputsNames.concat(newNames);
    let arrayOfKeywords = this.state.illustrationsKeywords.concat(newKeywords);

    for (const item of allIndexes) {
      item[0].preview = await getBase64(item[0].originFileObj);
      arrayOfPreviews.push(item[0].preview);
    }

    this.setState({
      previewImage: arrayOfPreviews,
      inputsNames: arrayOfNames,
      illustrationsKeywords: arrayOfKeywords
    });
  };

  handleCancel = () => {
    const fileListClone = [...this.state.fileList];
    fileListClone.splice(0, 1);

    this.setState({
      fileList: fileListClone,
      isCoverLoaded: !this.state.isCoverLoaded
    })
  };

  handleButtonCancel = (id) => {
    const searchIndex = this.state.fileListDnD[id].imageGroup;
    let updatedFileList = this.state.fileListDnD.filter(function (el) {
      return el.imageGroup !== searchIndex
    });

    const illustrationFormItemsClone = [...this.state.illustrationFormItems];
    illustrationFormItemsClone.splice(id, 1);

    const previewImageClone = [...this.state.previewImage];
    previewImageClone.splice(id, 1);

    const inputsNamesClone = [...this.state.inputsNames];
    inputsNamesClone.splice(id, 1);

    const illustrationsKeywordsClone = [...this.state.illustrationsKeywords];
    illustrationsKeywordsClone.splice(id, 1);

    this.setState({
      illustrationFormItems: illustrationFormItemsClone,
      previewImage: previewImageClone,
      inputsNames: inputsNamesClone,
      fileListDnD: updatedFileList,
      illustrationsKeywords: illustrationsKeywordsClone,
    })
  };

  handleSelectValueChange(selectedCategories, stateName) {
    this.setState({
      [stateName]: selectedCategories
    })
  }

  /** Method for change state. Triggers on form item change.
   * @param {string} stateName State name
   * @param {object} event Event object
   */
  handleStateChange(event, stateName) {
    this.setState({
      [stateName]: event.target.value
    })
  };

  /** For replace symbols from string
   * @param {string} stateName State name
   * @param {object} event Event object
   * */
  handleInputForNumberChange(event, stateName) {
    let inputValue = event.target.value;
    let newValue = parseInt(inputValue.replace(/[^0-9]/g, ''));
    newValue = (newValue > 2147483647) ? 2147483647 : newValue;

    if (Number(newValue)) {
      let fieldName = event.target.id.split('_')[1];
      this.createPackFormRef.current.setFieldsValue({
        [fieldName]: newValue
      });

      this.setState({
        [stateName]: newValue
      });
    }
  }

  handleTextAreaChange(event, stateName) {
    this.setState({
      [stateName]: event.target.value
    })
  }

  handleKeywordsChange(event, index) {
    const illustrationsKeywordsClone = [...this.state.illustrationsKeywords];
    illustrationsKeywordsClone[index] = event.target.value;

    this.setState({
      illustrationsKeywords: illustrationsKeywordsClone
    })
  }

  handleFormListItemChange(event, index) {
    let highlights = [...this.state.highlights];
    highlights[index] = event.target.value;

    this.setState({ highlights });
  }

  handleFormListItemDelete(itemName) {
    let highlights = [...this.state.highlights]
    highlights.splice(itemName, 1);

    this.setState({ highlights });
  }

  formOnFinish(values) {
    let formData = new FormData();
    let {
      highlights, presentationFileList, demoFile, fileList,
      bigCoverFileList, landingUrl, figmaPreview, craftworkId,
      packDescription, packShortDescription, selectedCategories,
      packKeywords, selectedType, selectedMood
    } = this.state;

    if (fileList && 'originFileObj' in fileList[0]) { formData.append('cover', fileList[0].originFileObj) }
    if (bigCoverFileList.length && 'originFileObj' in bigCoverFileList[0]) {
      formData.append('bigCover', bigCoverFileList[0].originFileObj)
    }
    if (landingUrl && landingUrl !== '') { formData.append('landingUrl', landingUrl) }
    if (figmaPreview && this.figmaPreview !== '') { formData.append('figmaPreview', figmaPreview) }
    if (craftworkId) { formData.append('craftworkId', craftworkId) }
    if (packDescription) { formData.append('description', packDescription) }
    if (packShortDescription) { formData.append('shortDescription', packShortDescription) }
    /** required fields */
    formData.append('authorId', '1');
    formData.append('typeId', selectedType);
    formData.append('name', values.packName);
    formData.append('moodId', selectedMood);

    if (highlights.length) {
      for (let i = 0; i < highlights.length; i++) {
        let withoutExtraSpaces = highlights[i]
          .replace(/\s+/g, ' ')
          .replace(/^\s+|\s+$/g, '');
        formData.append('highlights[]', withoutExtraSpaces);
      }
    }

    if (presentationFileList.length) {
      for (let i = 0; i < presentationFileList.length; i ++) {
        formData.append('presentationImages[]', presentationFileList[i].originFileObj);
      }
    } else {
      formData.append('presentationImages[]', '');
    }

    if (demoFile.length) { formData.append('demoFile', demoFile[0].originFileObj) }

    let keywordsData = packKeywords ? packKeywords.split(',') : null;
    if (keywordsData !== null) {
      for (let i = 0; i < keywordsData.length; i++) {
        const keyword = keywordsData[i].trim();
        if (keyword.length > 0) { formData.append('keywords[]', keyword) }
      }
    }

    for (let i = 0; i < selectedCategories; i++) { formData.append('categoryIds[]', selectedCategories[i]) }

    axios.post(`/api/pack`, formData)
      .then(response => {
        if (response.data.success === true) {
          const {history} = this.props;
          const {illustrationPackId} = response.data.result;
          /** @param { result:{illustrationPackId: number}} data */
          this.setState({ createdPackId: response.data.result.illustrationPackId, successPackCreated: true });
          openNotificationWithIcon('success', `Success`, `Pack ${response.data.result.illustrationPackId} successfully created`);
          history.push(`/pack/edit/${illustrationPackId}`)
        } else {
          openNotificationWithIcon('error', `Error`, response.data.message);
        }
      })
      .catch(function (error) { console.log(error) });
  }

  componentDidMount() { this.getSelectLists().catch(err => console.log(err)) }

  getSelectLists = async () => {
    const categories = await axios.get(`/api/category`);
    const moods = await axios.get(`/api/mood`);
    const types = await axios.get(`/api/type`);

    axios.all([categories, moods, types])
      .then(
        axios.spread((...responses) => {
          this.setState({
            /** @param { result:{categoriesData: object}} data */
            /** @param { result:{moodsData: object}} data */
            /** @param { result:{typesData: object}} data */
            categories: responses[0].data.result.categoriesData,
            moods: responses[1].data.result.moodsData,
            types: responses[2].data.result.typesData,
            selectedMood: responses[1].data.result.moodsData[0].id,
            selectedType: responses[2].data.result.typesData[0].id,
            isLoading: false,
          })
        })
      )
      .catch(errors => {
        console.log(errors);
      })
  };

  render() {
    const {
      fileList, isCoverLoaded, isBigCoverLoaded,
      bigCoverFileList, presentationFileList, demoFile
    } = this.state;

    return (
      <div className="pack-create">
        <Header />
        <div className="container-custom">
          <div className="row content-row">
            <div className="col-lg-2 col-md-3"> <NavSidebar /></div>
            <div className="col">
              <div className="row">
                <div className="col-lg-3 button-column">
                  <Link to={{ pathname: '/packs' }} >
                    <ButtonIcon class="neutral outline big">
                      <span><IconEscape /></span>
                      <span>Packs</span>
                    </ButtonIcon>
                  </Link>
                </div>
                <div className="col-lg-7 col-xl-5">
                  <h3>Create pack</h3>
                  <Form
                    ref = { this.createPackFormRef }
                    name='createPackFrom'
                    className='pack-form'
                    layout='vertical'
                    onFinish={this.formOnFinish}
                    initialValues={{ userName: 'Craftwork' }}
                  >
                    <Form.Item
                      label='Name'
                      name='packName'
                      rules={[{ required: true, message: 'Please input pack name!' }]}
                      onChange={(event) => this.handleStateChange(event, 'packName')}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label='Landing Url'
                      name='landingUrl'
                      rules={[{type: 'url', message: 'This field must be a valid url.'}]}
                      onChange={(event) => this.handleStateChange(event, 'landingUrl')}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label='Figma preview'
                      name='figmaPreview'
                      rules={[{type: 'url', message: 'This field must be a valid url.'}]}
                      onChange={(event) => this.handleStateChange(event, 'figmaPreview')}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label='Craftwork ID'
                      name='craftworkId'
                      value={this.state.craftworkId || ''}
                      onChange={(event) => this.handleInputForNumberChange(event, 'craftworkId')}
                    >
                      <Input />
                    </Form.Item>
                    <Form.List name='highlights'>
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={index === 0 ? 'Highlights' : ''}
                              required={false}
                              key={field.key}
                              onChange={(event) => this.handleFormListItemChange(event, index)}
                            >
                              <Form.Item
                                className={`form-list-item ${field.name}`}
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input highlight text",
                                  },
                                ]}
                              >
                                <Input placeholder="highlight"/>
                              </Form.Item>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button absolute-button"
                                  onClick={() => {
                                    remove(field.name);
                                    this.handleFormListItemDelete(field.name);
                                  }}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}>
                              Add highlight
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item
                      label='Description'
                      name='packDescription'
                    >
                      <Input.TextArea
                        maxLength={160}
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        onChange={(event) => this.handleStateChange(event, 'packDescription')}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Short description'
                      name='packShortDescription'
                    >
                      <Input.TextArea
                        maxLength={80}
                        autoSize={{ minRows: 3, maxRows: 4 }}
                        onChange={(event) => this.handleStateChange(event, 'packShortDescription')}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Status'
                      name='status'
                      initialValue={this.state.status[0].id}
                    >
                      <Select
                        onChange={(value) => this.handleSelectValueChange(value, 'selectedStatus')}
                      >
                        {this.state.status.map((stat) =>
                          <Select.Option key={stat.id + stat.name} value={stat.id}>{stat.name}</Select.Option>
                        )}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label='Cover'
                      className='pack-cover__container'
                    >
                      {isCoverLoaded === false &&
                      <Upload
                        listType='picture-card'
                        className='avatar-uploader'
                        fileList={fileList}
                        beforeUpload={() => false}
                        onChange={this.handleCoverUpload}
                      >
                        <div className={`ant-upload-text d-flex flex-column align-items-center justify-content-center`}>
                          <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: "5px" }}>
                              <IconPlus fill="#000000" />
                            </div>
                            Add image
                          </div>
                          <p className="ant-upload-hint">Png, 240×240px</p>
                        </div>
                      </Upload>
                      }
                      {isCoverLoaded === true &&
                      <div className="cover-card">
                        <div className="image-wrapper">
                          <img width={80} height={80} src={fileList[0].preview} alt=""/>
                        </div>
                        <div>
                          <p className="title">{fileList[0].name}</p>
                          <p className="sizes">240×240px</p>
                        </div>
                        <Button type="link" onClick={() => this.handleCancel()} icon={<IconCart fill="#CDCDCD" />}/>
                      </div>
                      }
                    </Form.Item>

                    <Form.Item
                      label='Big cover'
                      className='pack-cover__container'
                    >
                      <Upload
                        listType='picture-card'
                        className='avatar-uploader'
                        fileList={bigCoverFileList}
                        beforeUpload={() => false}
                        onChange={this.handleBigCoverUpload}
                      >
                        <div className={`ant-upload-text d-flex flex-column align-items-center justify-content-center`}>
                          <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: "5px" }}>
                              <IconPlus fill="#000000" />
                            </div>
                            Add image
                          </div>
                          <p className="ant-upload-hint">Png, 1600х1200px</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    {/*Blocked for now - need new backend realisation for add that*/}
                    {/*<Form.Item*/}
                    {/*  label='Presentation images'*/}
                    {/*  className='pack-cover__container'*/}
                    {/*>*/}
                    {/*  <Upload*/}
                    {/*    listType='picture-card'*/}
                    {/*    className='ant-picture-wall-uploader ant-upload-no-preview '*/}
                    {/*    fileList={presentationFileList}*/}
                    {/*    multiple={true}*/}
                    {/*    beforeUpload={() => false}*/}
                    {/*    onChange={this.handlePresentationUpload}*/}
                    {/*  >*/}
                    {/*    <div>*/}
                    {/*      <PlusOutlined />*/}
                    {/*      <div style={{ marginTop: 8 }}>Upload</div>*/}
                    {/*    </div>*/}
                    {/*  </Upload>*/}
                    {/*</Form.Item>*/}
                    <Form.Item
                      label='Author username'
                      name='userName'
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      label='Type'
                      name='type'
                      rules={[{ required: true, message: 'Please input pack type!' }]}>
                      <Select onChange={(value) => this.handleSelectValueChange(value, 'selectedType')}>
                        {this.state.types.map((type) => <Select.Option key={type.id + type.name} value={type.id}>{type.name}</Select.Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Mood'
                      name='mood'
                      rules={[{ required: true, message: 'Please input pack mood!' }]}>
                      <Select onChange={(value) => this.handleSelectValueChange(value, 'selectedMood')}>
                        {this.state.moods.map((mood) => <Select.Option key={mood.id + mood.name} value={mood.id}>{mood.name}</Select.Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Category'
                      name='category'
                      rules={[{ required: true, message: 'Please input pack mood!' }]}
                    >
                      <Select
                        mode='multiple'
                        maxTagCount='responsive'
                        onChange={(value) => this.handleSelectValueChange(value, 'selectedCategories')}
                      >
                        {this.state.categories.map((category) =>
                          <Select.Option key={category.id + category.name} value={category.id}>{category.name}</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Keywords'
                      name='keywords'
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        onChange={(event) => this.handleStateChange(event, 'packKeywords')}
                      />
                    </Form.Item>
                    <Form.Item label="Demo Files" name='demoFile' className='show-text-list'>
                      <Upload
                        listType='text'
                        beforeUpload={() => false}
                        onChange={(info)=> this.handleFileChange(info, 'demoFile')}
                        fileList={demoFile}
                      >
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" disabled={!isCoverLoaded || !isBigCoverLoaded} htmlType='submit' style={{ marginTop: '50px' }}>
                        Create Pack
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer bordered={true} />
      </div>
    )
  }
}

const PackCreateRouter = withRouter(PackCreate);
export default PackCreateRouter;
