import moment from "moment";

export const getRequestPeriod = (value) => {
  let prevFrom, prevTo, curFrom, curTo, fixedPeriod;
  switch (value) {
    case "Day":
      prevFrom = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      prevTo = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      curFrom = moment().format("YYYY-MM-DD");
      curTo = moment().format("YYYY-MM-DD");
      fixedPeriod = "Day";
      break;
    case "Week":
      prevFrom = moment()
        .subtract(2, "week")
        .format("YYYY-MM-DD");
      prevTo = moment()
        .subtract(1, "week")
        .format("YYYY-MM-DD");
      curFrom = moment()
        .subtract(1, "week")
        .format("YYYY-MM-DD");
      curTo = moment().format("YYYY-MM-DD");
      fixedPeriod = "Week";
      break;
    case "Month":
      prevFrom = moment()
        .subtract(2, "month")
        .format("YYYY-MM-DD");
      prevTo = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      curFrom = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      curTo = moment().format("YYYY-MM-DD");
      fixedPeriod = "Month";
      break;
    case "Year":
      prevFrom = moment()
        .subtract(2, "year")
        .format("YYYY-MM-DD");
      prevTo = moment()
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      curFrom = moment()
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      curTo = moment().format("YYYY-MM-DD");
      fixedPeriod = "Year";
      break;
    default:
      prevFrom = moment()
        .subtract(2, "month")
        .format("YYYY-MM-DD");
      prevTo = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      curFrom = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      curTo = moment().format("YYYY-MM-DD");
      fixedPeriod = "Month";
  }

  return {
    prevFrom,
    prevTo,
    curFrom,
    curTo,
    fixedPeriod
  }
}

export const getAllIndexes = (arr, val) => {
  var indexes = [], i;
  for (i = 0; i < arr.length; i++)
    if (arr[i].name.replace(/\.[^/.]+$/, "") === val) {
      indexes.push(arr[i]);
    }
  const unique = indexes.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set()));
  return unique;
}

export const getAllNames = (arr) => {
  let names = [];

  for (let i = 0; i < arr.length; i++) {
    names.push(arr[i].name.replace(/\.[^/.]+$/, ""))
  };
  return names;
}

export const getAllElements = (arr) => {
  let names = [];

  for (let i = 0; i < arr.length; i++) {
    names.push(arr[i].id)
  };

  return names;
}

export const reduceAllIndexes = (originalArr, unicNamesArr) => {
  let indexes = [], x;
  for (x = 0; x < unicNamesArr.length; x++) {
    indexes.push(getAllIndexes(originalArr, unicNamesArr[x]));
  }
  return indexes;
}

export const setFileImageGroup = (filesList, unicNames) => {
  for (let index = 0; index < filesList.length; index++) {
    var imageGroupIndex = unicNames.indexOf(filesList[index].name.replace(/\.[^/.]+$/, ""));
    filesList[index].imageGroup = imageGroupIndex;
  }
  const unique = filesList.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set()));
  return unique;
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const findDifferences = (a1, a2) => {
  return a1.filter(i => !a2.includes(i)).concat(a2.filter(i => !a1.includes(i)))
};

/** Function for format variable in camel case
 * @param {string} basis Basic variable part
 * @param {string} prefix
 * @param {string} postfix
 * @return {string} Final variable name in camel case format
 * */
export const variableNameFormation = ({ basis, prefix = '', postfix = '' }) => {
  let finalValue;

  if (typeof basis === 'string' && typeof prefix === 'string' && typeof postfix === 'string') {
    finalValue = basis.charAt(0).toLowerCase() + basis.slice(1);
    if (typeof prefix === 'string' && prefix.length) {
      prefix = prefix.charAt(0).toLowerCase() + prefix.slice(1);
      finalValue = prefix + finalValue.charAt(0).toUpperCase() + finalValue.slice(1);
    }
    if (typeof postfix === 'string' && postfix.length) {
      postfix = postfix.charAt(0).toUpperCase() + postfix.slice(1);
      finalValue += postfix;
    }
  } else {
    let params = '';
    if(typeof basis !== 'string') params += '"basis" ';
    if(typeof prefix !== 'string') params += '"prefix" ';
    if(typeof postfix !== 'string') params += '"postfix" ';

    throw new Error(`Param/s: ${params}must be a string`);
  }

  return finalValue;
};
