import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Footer.css';
import {StorytaleLogo} from "./Icons";

class Footer extends Component {

  render() {

    const { bordered } = this.props;
    const date = new Date();

    return (
      <footer className={`site-footer ${bordered === true ? `bordered` : null}`}>
        <div className="container-custom">
          <div className="row">
            <div className="col">
              <div className="wrapper">
                <div className="first">
                  <Link className="link" to="/" >
                    <StorytaleLogo/>
                  </Link>
                  <p className="footer-text">High-quality design assets to bring life into you projects</p>
                </div>
                <div className="second"><p className="header">Explore</p>
                  <ul className="list">
                    <li>
                      <button className="link">Catalog</button>
                    </li>
                    <li>
                      <button className="link">Freebies</button>
                    </li>
                    <li>
                      <button className="link">Updates</button>
                    </li>
                    <li>
                      <button className="link">Showcase</button>
                    </li>
                    <li>
                      <button className="link">Prices</button>
                    </li>
                  </ul>
                </div>
                <div className="third">
                  <p className="header">Legals</p>
                  <ul className="list">
                    <li>
                      <button className="link">Privacy</button>
                    </li>
                    <li>
                      <button className="link">Licenses</button>
                    </li>
                    <li>
                      <button className="link">Terms</button>
                    </li>
                    <li>
                      <button className="link">Refunds</button>
                    </li>
                    <li>
                      <button className="link">Cookies</button>
                    </li>
                  </ul>
                </div>
                <div className="fourth">
                  <p className="header">Company</p>
                  <ul className="list">
                    <li>
                      <button className="link">Custom work</button>
                    </li>
                    <li>
                      <button className="link">About</button>
                    </li>
                    <li>
                      <button className="link">Contacts</button>
                    </li>
                    <li>
                      <button className="link">Help</button>
                    </li>
                  </ul>
                </div>
                <div className="fifth">
                  <div className="left">
                    <p className="bottom-text">Created on sunshine coast by Craftwork © 2020 - {date.getFullYear()}</p>
                  </div>
                  <div className="right">
                    <ul className="socials">
                      <li>
                        <a href="https://dribbble.com/storytale" target="_blank" rel="noreferrer" className="bottom-text bottom-link">Dribbble</a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/storytale.io" target="_blank" rel="noreferrer" className="bottom-text bottom-link">Instagram</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
