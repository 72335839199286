import React from "react";

export default function PeriodTabsItem(props) {
  var class_name = props.isActive ? 'tabs__item active' : 'tabs__item';

  return (
    <div className={class_name} onClick={() => {props.onClick(props.name) }}>
      {props.name}
    </div>
  )
}
