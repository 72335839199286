import Footer from '../../components/Footer';
import Header from '../../components/Header';

import { Form, Input, Button } from 'antd';
import ErrorBoundary from '../../components/ErrorBoundary';
import { openNotificationWithIcon } from '../../lib/notifications';

import axios from 'axios';

import 'antd/dist/antd.css';
import './index.css';

// Redux
import { connect } from 'react-redux';
import { authorizeAction } from '../../actions';
import { Redirect, useHistory } from 'react-router';
import React from "react";

// Redux mapStateToProps
const mapStateToProps = state => ({
  authorized: state.authorizeReducer.authorized,
})

function Login(props) {

  const history = useHistory();

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append('userName', values.username);
    formData.append('password', values.password);

    axios.post(`/api/user/login`, formData)
      .then(response => {
        if (response.data.success === true) {
          openNotificationWithIcon('success', 'Success', "Success");
          props.dispatch(authorizeAction());
          history.push('/');
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (localStorage.getItem('storyAdminUser') === 'false' || !localStorage.getItem('storyAdminUser'))
    return (
      <ErrorBoundary>
        <Header/>
          <div className='container-custom Login'>
            <h2 className='Login__title'>Login</h2>
            <Form
              name='loginForm'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className='Login__form'
            >
              <Form.Item
                label='Username or Email address'
                name='username'
                rules={[
                  {
                    required: true,
                    message: 'Please input your username or email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<span className='AccountForm__label_withRightLink'>Password</span>}
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
          <Footer bordered={true} />
      </ErrorBoundary>
    )
    if (localStorage.getItem('storyAdminUser') === 'true')
    return (
      <Redirect to='/statistic' />
    )
}

export default connect(mapStateToProps)(Login);
