const authorizeReducer = (state = {} , action) => {
  switch (action.type) {
    case 'SET_AUTHORIZED':
    return {
      authorized: action.payload,
    }
    case 'SET_UNAUTHORIZED':
    return {
      authorized: action.payload,
    }
    default:
     return state
  }
};

export default authorizeReducer;
