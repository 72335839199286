export function renameStat(statName) {
  var renamedStat;
  switch (statName) {
    case 'createdCount':
      renamedStat = 'Created';
      break;
    case 'downloadCount':
      renamedStat = 'Dowloaded';
      break;
    default:
      renamedStat = statName.replace('Count', '');
      renamedStat = renamedStat.charAt(0).toUpperCase() + renamedStat.slice(1);
      break;
  }

  return renamedStat;
}
