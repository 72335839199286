import React from "react";

import './TableWrapper.css'

export default function TableWrapper(props) {
  return (
    <div className='table-wrapper'>
      {props.children}
    </div>  
  )
}
