import React from 'react';

import './Tabs.css';

export default function PeriodTabs(props) {
  return (
    <div className='tabs'>
      {props.children}
    </div>
  )
}
