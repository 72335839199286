import React from 'react';

import { localeString } from '../lib/localeString';
import { renameStat } from '../lib/renameStat';
import {SvgProgressMinus, SvgProgressPlus} from "./Icons";

import './StatisticCard.css';

export default function StatisticCard({ name, value, prevValue, isSingle, days}) {
  return (
    <div className='statistic-card'>
      <div className='statistic-card__header'>
        <h3 className='statistic-card__name'>{ renameStat(name) }</h3>
        {(() => {
          if (!isSingle) {
            if (prevValue > value)
              return <p className='statistic-card__diff_minus'><SvgProgressMinus />{ localeString(value - prevValue) }</p>
            if (prevValue < value)
              return <p className='statistic-card__diff_plus'><SvgProgressPlus />{ localeString(value - prevValue) }</p>
            else
              return <p className='statistic-card__diff_null'> -- { localeString(value - prevValue) }</p>
          }
        })()}
      </div>
      <div className='statistic-card__body'>
        <p className='statistic-card__value'>{ localeString(value) }</p>
        { isSingle
          ? days >= 0 && <p className='statistic-card__period'> <span>During:</span> <br/><span>{days}</span> Days </p>
          : <p className='statistic-card__period'><span>Prev:</span> <br/><span>{ localeString(prevValue) }</span></p> 
        }
      </div>
    </div>  
  )
}
