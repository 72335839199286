import React, { Component } from "react";
import NavSidebar from "../../components/NavSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TableWrapper from "../../components/TableWrapper";
import axios from "axios";
import moment from "moment";
import "../../base-css.css";
import { Table, Pagination, Avatar} from "antd";
import PacksHeader from "../../components/PacksHeader";
import { Link } from "react-router-dom";
import { openNotificationWithIcon } from "../../lib/notifications";

const datePickerDateFormat = "Do MMM YY";

const status = [
  { 'id': 1, 'name': 'Drafted' },
  { 'id': 2, 'name': 'Review' },
  { 'id': 3, 'name': 'Published' },
  { 'id': 4, 'name': 'Trashed' }
]

const columns = [
  {
    title: "",
    key: "cover",
    dataIndex: "cover",
    render: (text, record) => (
      <>
        <Avatar src={record.cover} />
      </>
    )
  },
  {
    title: "Name",
    key: "name",
    render: (text, record) => (
      <Link
        to={`/pack/${record.id}`}
        style={{ borderBottom: '1px solid #2C3FEE'}}
      >
        <span style={{ color: "#2C3FEE" }}>{record.name}</span>
      </Link>
    )
  },
  {
    title: "Status",
    key: "status",
    render: (text, record) => (
      <>
        <span>{status[record.status - 1].name}</span>
      </>
    )
  },
  {
    title: "Creation Date",
    key: "createdDate",
    render: (text, record) => moment(record.createdDate).format(datePickerDateFormat)
  },
  {
    title: "Author",
    dataIndex: "author",
    key: "author"
  },
  {
    title: "Views",
    dataIndex: "views",
    key: "views"
  },
  {
    title: "Downloads",
    dataIndex: "downloadCount",
    key: "downloadCount"
  },
  {
    title: "Total earning",
    dataIndex: "total earning",
    key: "total earning"
  },
  {
    title: "Count",
    dataIndex: "illustrationCount",
    key: "illustrationCount",
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (

      <Link
        to={`/pack/edit/${record.id}`}
        style={{color: '#000', borderBottom: '1px solid #000'}}
      >
       Edit
      </Link>
    )
  }
];

class Packs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      allPage: 1,
      to: null,
      from: null,
      allCount: null,
      params: {
        from: moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        page: 1
      }
    };

    this.onChangeRequest = this.onChangeRequest.bind(this);
    this.onChangeCalendarFrom = this.onChangeCalendarFrom.bind(this);
    this.onChangeCalendarTo = this.onChangeCalendarTo.bind(this);
    this.onChangePagination = this.onChangePagination.bind(this);
  }

  onChangePagination(page) {

    this.setState(
      prevState => {
        let params = Object.assign({}, prevState.params);
        params.page = page;
        return { params };
      },
      () => this.onChangeRequest(this.state.params)
    );
  }

  onChangeCalendarFrom(date) {
    if (date === null) {
      return;
    }

    const modernDate = moment(date._d).format("YYYY-MM-DD");

    this.setState(
      prevState => {
        let params = Object.assign({}, prevState.params);
        params.from = modernDate;
        return { params };
      },
      () => this.onChangeRequest(this.state.params)
    );
  }

  onChangeCalendarTo(date) {
    if (date === null) {
      return;
    }

    const modernDate = moment(date._d).format("YYYY-MM-DD");

    this.setState(
      prevState => {
        let params = Object.assign({}, prevState.params);
        params.to = modernDate;
        return { params };
      },
      () => this.onChangeRequest(this.state.params)
    );
  }

  onChangeRequest(reguest) {

    window.scrollTo(0, 0);

    let search = {
      params: reguest
    };

    axios
      .get(`/api/pack`, search)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            data: response.data.result.packsData,
            from: response.data.result.pagination.from,
            to: response.data.result.pagination.to
          });
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            response.data.message
          );
          console.log(this);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {
    let search = {
      params: this.state.params
    };

    axios
      .get(`/api/pack`, search)
      .then(response => {
        if (response.data.success === true) {
          const { packsData, pagination } = response.data.result;
          const { allPage, from, to, allCount } = pagination;
          this.setState({
            data: packsData,
            allPage: allPage,
            from: from,
            to: to,
            allCount: allCount
          });
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            response.data.message
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const { data, allPage, from, to, allCount } = this.state;
    return (
      <>
        <Header />
        <div className="container-custom">
          <div className="row">
            <div className="col-lg-2 col-md-3" style={{ marginTop: "70px" }}>
              <NavSidebar />
            </div>
            <div className="col" style={{ marginTop: "70px" }}>
              <h3>Packs</h3>

              <PacksHeader
                from={from}
                to={to}
                allCount={allCount}
                onChangeCalendarFrom={this.onChangeCalendarFrom}
                onChangeCalendarTo={this.onChangeCalendarTo}
              />

              <div style={{ marginTop: "60px" }}>
                <TableWrapper>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                  {allPage > 1 ? (
                    <div style={{ marginTop: "40px" }}>
                      <Pagination
                        defaultCurrent={1}
                        total={allPage + "0"}
                        onChange={this.onChangePagination}
                      />
                    </div>
                  ) : null}
                </TableWrapper>
              </div>
            </div>
          </div>
        </div>
        <Footer bordered={true} allCount={allCount} />
      </>
    );
  }
}

export default Packs;
