import React, { Component } from "react";
import NavSidebar from '../../components/NavSidebar';
import { Link } from "react-router-dom";
import { Form, Input, Button, Select, Row, Col } from 'antd';
import axios from 'axios';
import {IconEscape} from '../../components/Icons';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ButtonIcon from "../../components/ButtonIcon";
import { openNotificationWithIcon } from "../../lib/notifications";

// Styles
import './index.css';

class PlanCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: [
                { 'id': 1, 'name': 'Drafted' },
                { 'id': 2, 'name': 'Public' },
                { 'id': 3, 'name': 'Private' },
                { 'id': 4, 'name': 'Renewal Only' },
                { 'id': 5, 'name': 'Trashed' }
            ],
            selectedStatus: 1,
            selectedPeriod: 1,
            duration: 1,
            charge: 1,
            period: [
                { 'id': 1, 'name': 'Day' },
                { 'id': 2, 'name': 'Month' },
            ],
            planName: '',
            planPrice: '',
            downloadLimit: '',
            description: '',
        };

        this.handleSelectValueChange = this.handleSelectValueChange.bind(this);
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
        this.formOnFinish = this.formOnFinish.bind(this);
    }

    onChangeNameValue = (value, index) => {
        const names = this.state.inputsNames.slice(0);
        names[index] = value;

        this.setState({
            inputsNames: names,
        });
    };

    handleSelectValueChange(value) {
        this.setState({
            selectedStatus: value
        })
    }

    handleSelectDurationChange(value) {
        this.setState({
            selectedPeriod : value
        })
    }

    handleInputChange(event, statname) {
        this.setState({
            [statname]: event.target.value
        })
    }

    handleTextAreaChange(event, statname) {
        this.setState({
            [statname]: event.target.value
        })
    }

    formOnFinish() {
        let formData = new FormData();

        const durationLabel = this.state.period.filter(x => x.id === this.state.selectedPeriod);
        const target = durationLabel[0].name.toLowerCase();

        formData.append('name', this.state.planName);
        formData.append('price', this.state.planPrice);
        formData.append('duration_count', this.state.duration);
        formData.append('duration_label', target);
        formData.append('downloadLimit', this.state.downloadLimit);
        formData.append('description', this.state.description);
        formData.append('chargePeriod[count]', this.state.charge);
        formData.append('chargePeriod[label]', target);

        axios.post(`/api/subscriptionPlan`, formData)
            .then(response => {
                if (response.data.success === true) {
                    openNotificationWithIcon('success', 'Well Done', `Plan ${this.state.planName} was successfully created with id ${response.data.result.subscriptionPlan.id}`);
                } else {
                    openNotificationWithIcon('error', 'Error', response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="plan-create">
                <Header />
                <div className="container-custom">
                    <div className="row content-row">
                        <div className="col-lg-2 col-md-3">
                            <NavSidebar />
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-3 button-column">
                                    <Link to={{ pathname: '/plans' }} >
                                        <ButtonIcon class="neutral outline big">
                                          <span>
                                            <IconEscape />
                                          </span>
                                            <span>Plans</span>
                                        </ButtonIcon>
                                    </Link>
                                </div>
                                <div className="col-lg-5">
                                    <h2 className='dashboard__title'>New Plan</h2>
                                    <Form
                                        name='createPlanForm'
                                        className='plan-form'
                                        layout='vertical'
                                        onFinish={this.formOnFinish}
                                    >
                                        <Form.Item
                                            label='Name'
                                            name='planName'
                                            rules={[{ required: true, message: 'Please input plan name!' }]}
                                            onChange={(event) => this.handleInputChange(event, 'planName')}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label='Price'
                                            name='planPrice'
                                            rules={[{ required: true, message: 'Please input plan price!' }]}
                                            onChange={(event) => this.handleInputChange(event, 'planPrice')}
                                        >
                                            <Input type="number" min="0" step="0.01"/>
                                        </Form.Item>
                                        <Form.Item
                                            label='Download limit'
                                            name='dowloadLimit'
                                            rules={[{ required: true, message: 'Please input download limit!' }]}
                                            onChange={(event) => this.handleInputChange(event, 'downloadLimit')}
                                        >
                                            <Input type="number" min="0"/>
                                        </Form.Item>
                                        <Row gutter={24}>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label='Duration'
                                                    name='duration'
                                                    rules={[{ required: true, message: 'Please input duration!' }]}
                                                    onChange={(event) => this.handleInputChange(event, 'duration')}
                                                >
                                                    <Input type="number" min="1" />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    className="custom-item"
                                                    label='Duration period'
                                                    name='period'
                                                    initialValue={this.state.period[0].id}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        onChange={(value) => this.handleSelectDurationChange(value)}
                                                    >
                                                        {this.state.period.map((stat) =>
                                                            <Select.Option key={stat.id + stat.name} value={stat.id}>{stat.name}</Select.Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={24}>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                  label='Charge period'
                                                  name='chargeCount'
                                                  dependencies={['duration']}
                                                  rules={[{ required: true, message: 'Please input charge!' }, (
                                                    { getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || value % getFieldValue('duration') === 0) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error(`The charge period number must be a multiple of duration period number (in this case ${getFieldValue('duration')})! `));
                                                        },
                                                    }
                                                  )]}

                                                  onChange={(event) => this.handleInputChange(event, 'charge')}
                                                  hasFeedback
                                                >
                                                    <Input type="number" min={this.state.duration} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <p className={'charge-label'}>
                                                    {this.state.period[this.state.selectedPeriod - 1].name}
                                                </p>
                                            </Col>
                                        </Row>

                                        <Form.Item
                                            label='Status'
                                            name='status'
                                            initialValue={this.state.status[0].id}
                                        >
                                            <Select
                                                onChange={(value) => this.handleSelectValueChange(value)}
                                            >
                                                {this.state.status.map((stat) =>
                                                    <Select.Option key={stat.id + stat.name} value={stat.id}>{stat.name}</Select.Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label='Description'
                                            name='description'
                                            initialValue={''}
                                            onChange={(event) => this.handleTextAreaChange(event, 'description')}
                                            rules={[{ required: true, message: 'Please input plan description!' }]}
                                        >
                                            <Input.TextArea
                                                maxLength={160}
                                                autoSize={{ minRows: 4, maxRows: 6 }}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                                <Button type="primary" htmlType='submit' style={{ marginTop: '50px' }}>
                                                    Create Plan
                                                </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer bordered={true} />
            </div>
        )
    }
}

export default PlanCreate;
