import React, { Component } from "react";

import { connect } from 'react-redux';

import "./Header.css";

const picture  = "https://storytale-public2.b-cdn.net/static/assets/admin/assets/images/1.png";

const mapStateToProps = state => ({
  userName: state.userReducer.user.name || null,
  userEmail: state.userReducer.user.email || null
})

class Header extends Component {
  render() {
    const locaStorageName = localStorage.getItem('storyAdminUserName') !== null
      ? localStorage.getItem('storyAdminUserName').charAt(0).toUpperCase() + localStorage.getItem('storyAdminUserName').slice(1)
      : '';
    return (
      <div className="header">
        <div className="wrapper">
          <div className="item">
            <span>
              <svg width="148" height="40" viewBox="0 0 148 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M58.496 17.792L61.232 16.6639C60.368 14.384 57.536 13.448 55.712 13.664C52.976 14 51.416 15.9439 51.416 17.7199C51.416 19.1119 52.736 20.384 54.2 20.84C55.424 21.224 58.448 21.32 58.304 22.712C58.208 23.6 56.744 23.8879 55.784 23.672C54.824 23.4559 54.08 22.688 53.792 21.92L50.96 23.144C51.56 24.656 53.168 26.504 56.36 26.504C59.84 26.504 61.472 24.392 61.472 22.784C61.472 21.2 60.68 19.6399 58.088 19.016C55.592 18.4159 54.512 18.2959 54.512 17.408C54.512 16.784 55.448 16.4479 56.264 16.4479C56.912 16.4479 58.112 16.808 58.496 17.792Z" fill="black"/>
                <path d="M70.7795 25.76L69.8915 23.024C69.3875 23.336 68.3555 23.5279 67.8035 22.9039C67.4675 22.5199 67.4195 22.184 67.4195 20.528V17.024H70.2515V14.2639H67.4435V10.7119H64.3955V13.472C64.3955 14 64.2275 14.2639 63.4835 14.2639H62.1875V17.024H64.2515V22.5199C64.2515 23.8879 65.0435 25.784 67.2035 26.216C68.9795 26.576 69.8435 26.12 70.7795 25.76Z" fill="black"/>
                <path d="M70.789 20.12C70.789 23.576 73.525 26.528 77.101 26.528C80.653 26.528 83.389 23.576 83.389 20.12C83.389 16.6639 80.653 13.8559 77.101 13.8559C73.525 13.8559 70.789 16.6639 70.789 20.12ZM73.957 20.1439C73.957 18.1279 75.493 16.7599 77.125 16.7599C78.757 16.7599 80.269 18.1279 80.269 20.1439C80.269 22.3039 78.757 23.576 77.125 23.576C75.493 23.576 73.957 22.3039 73.957 20.1439Z" fill="black"/>
                <path d="M87.9603 26.096L84.8403 26.072V14.3359H87.9603V16.0879C88.3443 15.1519 88.9203 14.576 90.0963 14.1439C90.9603 13.8319 92.4243 13.904 93.2403 14.3839L92.3763 17.24C91.4163 16.784 89.8323 16.7119 88.9203 17.7439C88.1043 18.6799 87.9603 19.3759 87.9603 22.4959V26.096Z" fill="black"/>
                <path d="M95.4158 31.424H98.8718L106.288 14.2639H102.832L99.8798 21.584L96.6878 14.2639L93.2318 14.24L98.1518 25.5919L95.4158 31.424Z" fill="black"/>
                <path d="M115.359 25.76L114.471 23.024C113.967 23.336 112.935 23.5279 112.383 22.9039C112.047 22.5199 111.999 22.184 111.999 20.528V17.024H114.831V14.2639H112.023V10.7119H108.975V13.472C108.975 14 108.807 14.2639 108.063 14.2639H106.767V17.024H108.831V22.5199C108.831 23.8879 109.623 25.784 111.783 26.216C113.559 26.576 114.423 26.12 115.359 25.76Z" fill="black"/>
                <path d="M120.578 26.4799C122.306 26.4799 123.386 25.496 123.914 24.752L124.01 26.12H127.154L127.082 18.2479C127.082 16.5679 126.146 15.0319 123.962 14.216C122.306 13.592 120.29 13.832 119.066 14.528C117.362 15.488 116.714 16.784 116.546 17.768L119.354 18.368C119.642 16.832 120.842 16.184 122.354 16.568C124.01 16.9999 123.746 18.368 123.41 18.632C123.074 18.896 121.682 19.016 120.506 19.184C117.914 19.52 116.09 20.696 116.354 23.312C116.618 25.256 118.034 26.4799 120.578 26.4799ZM121.946 23.96C120.53 24.248 119.498 23.816 119.498 22.6639C119.498 21.056 121.394 21.632 123.962 21.224C124.13 22.424 123.482 23.648 121.946 23.96Z" fill="black"/>
                <path d="M132.885 8.64795H129.549V26.1679H132.885V8.64795Z" fill="black"/>
                <path d="M134.8 20.1439C134.8 15.8479 137.92 13.904 141.112 13.904C144.28 13.904 147.376 15.848 147.016 20.984L138.064 21.08C138.064 22.016 138.952 23.552 140.92 23.672C142.36 23.768 143.272 23.096 143.8 22.088L146.752 23C146.152 24.464 144.28 26.336 141.16 26.336C137.32 26.336 134.8 23.5759 134.8 20.1439ZM138.064 18.7039L143.704 18.68C143.704 17.552 142.504 16.3279 140.632 16.4479C139.504 16.5199 138.208 17.36 138.064 18.7039Z" fill="black"/>
                <g clipPath="url(#clip0)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M2.77469 5.0792C0.848633 7.96175 0.848633 11.9745 0.848633 20C0.848633 28.0255 0.848633 32.0382 2.77469 34.9208C3.6085 36.1687 4.67994 37.2401 5.92783 38.0739C8.81038 40 12.8231 40 20.8486 40C28.8741 40 32.8868 40 35.7694 38.0739C37.0173 37.2401 38.0887 36.1687 38.9225 34.9208C40.8486 32.0382 40.8486 28.0255 40.8486 20C40.8486 11.9745 40.8486 7.96175 38.9225 5.0792C38.0887 3.83131 37.0173 2.75987 35.7694 1.92606C32.8868 0 28.8741 0 20.8486 0C12.8231 0 8.81038 0 5.92783 1.92606C4.67994 2.75987 3.6085 3.83131 2.77469 5.0792ZM19.8983 5.51184H21.5263L25.751 13.3015L30.5435 9.20594L31.6425 10.3049L27.5421 15.0925L35.3364 19.3219V20.9501L27.5657 17.7027L31.5771 29.7604L30.4215 30.9161L25.72 19.5037L21.576 34.4882H19.978L23.1778 19.4593L11.1629 30.8041L10.0442 29.6854L21.3842 17.6658L6.36004 20.8703V19.2722L21.3397 15.1236L9.93208 10.4268L11.0877 9.2711L23.1409 13.2778L19.8983 5.51184ZM11.1689 9.61422L23.6717 13.7704L11.1689 9.61422ZM35.0363 19.5005L35.0364 20.4996V19.5005L27.0729 15.1792L35.0363 19.5005ZM21.3477 5.81184H20.3486H21.3477L25.6641 13.7704L21.3477 5.81184Z" fill="black"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="40" height="40" fill="white" transform="translate(0.848633)"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
          <div className="item">
            {this.props.userName !== null
              ? <span className='header__username'>{this.props.userName.charAt(0).toUpperCase() + this.props.userName.slice(1)}</span>
              : <span className='header__username'>{locaStorageName}</span>
            }
            <span>
              <img width="40" height="40" style={{width: 'auto'}} src={picture} alt="avatar" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Header);
