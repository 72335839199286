import React from "react";

function IconPlus(props) {
  const fill = props.fill || "#ffffff";
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="16" height="16" rx="5" stroke={fill} strokeWidth="1.75" />
      <path d="M12 9L12 15" stroke={fill} strokeWidth="1.75" strokeLinecap="round" />
      <path d="M15 12H9" stroke={fill} strokeWidth="1.75" strokeLinecap="round" />
    </svg>
  );
}

function IconEscape(props) {
  const fill = props.fill || "#000000";
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9V7.41C10 6.52 8.92 6.07 8.29 6.7L3.7 11.29C3.31 11.68 3.31 12.31 3.7 12.7L8.29 17.29C8.92 17.92 10 17.48 10 16.59V14.9C15 14.9 18.5 16.5 21 20C20 15 17 10 10 9Z"
        fill={fill}
      ></path>
    </svg>
  );
}

function NotificationClose(props) {
  return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="15" cy="15" r="15" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0503 9.94967C9.65981 10.3402 9.65981 10.9734 10.0503 11.3639L13.5859 14.8994L10.0503 18.4349C9.65981 18.8255 9.65981 19.4586 10.0503 19.8492C10.4409 20.2397 11.074 20.2397 11.4645 19.8492L15.0001 16.3136L18.5356 19.8492C18.9261 20.2397 19.5593 20.2397 19.9498 19.8492C20.3404 19.4586 20.3404 18.8255 19.9498 18.4349L16.4143 14.8994L19.9498 11.3639C20.3404 10.9734 20.3404 10.3402 19.9498 9.94967C19.5593 9.55914 18.9261 9.55914 18.5356 9.94967L15.0001 13.4852L11.4645 9.94967C11.074 9.55914 10.4409 9.55914 10.0503 9.94967Z" fill="white"/>
      </svg>
  );
}

function IconOpen(props) {
  const fill = props.fill || "#ffffff";
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4976 13.0003C19.2766 13.0003 19.0646 13.0881 18.9083 13.2444C18.752 13.4006 18.6643 13.6126 18.6643 13.8336V18.8336C18.6643 19.0546 18.5765 19.2666 18.4202 19.4229C18.2639 19.5791 18.0519 19.6669 17.8309 19.6669H6.16425C5.94324 19.6669 5.73127 19.5791 5.57499 19.4229C5.41871 19.2666 5.33092 19.0546 5.33092 18.8336V7.16694C5.33092 6.94593 5.41871 6.73397 5.57499 6.57769C5.73127 6.42141 5.94324 6.33361 6.16425 6.33361H11.1642C11.3853 6.33361 11.5972 6.24581 11.7535 6.08953C11.9098 5.93325 11.9976 5.72129 11.9976 5.50027C11.9976 5.27926 11.9098 5.0673 11.7535 4.91102C11.5972 4.75474 11.3853 4.66694 11.1642 4.66694H6.16425C5.50121 4.66694 4.86532 4.93033 4.39648 5.39917C3.92764 5.86802 3.66425 6.5039 3.66425 7.16694V18.8336C3.66425 19.4966 3.92764 20.1325 4.39648 20.6014C4.86532 21.0702 5.50121 21.3336 6.16425 21.3336H17.8309C18.494 21.3336 19.1298 21.0702 19.5987 20.6014C20.0675 20.1325 20.3309 19.4966 20.3309 18.8336V13.8336C20.3309 13.6126 20.2431 13.4006 20.0868 13.2444C19.9306 13.0881 19.7186 13.0003 19.4976 13.0003ZM6.99758 13.6336V17.1669C6.99758 17.388 7.08538 17.5999 7.24166 17.7562C7.39794 17.9125 7.6099 18.0003 7.83092 18.0003H11.3642C11.4739 18.0009 11.5826 17.9799 11.6842 17.9384C11.7857 17.8969 11.878 17.8358 11.9559 17.7586L17.7226 11.9836L20.0892 9.66694C20.1674 9.58947 20.2294 9.4973 20.2717 9.39576C20.314 9.29421 20.3357 9.18528 20.3357 9.07527C20.3357 8.96526 20.314 8.85634 20.2717 8.75479C20.2294 8.65324 20.1674 8.56108 20.0892 8.48361L16.5559 4.90861C16.4784 4.8305 16.3863 4.76851 16.2847 4.7262C16.1832 4.68389 16.0743 4.66211 15.9643 4.66211C15.8542 4.66211 15.7453 4.68389 15.6438 4.7262C15.5422 4.76851 15.4501 4.8305 15.3726 4.90861L13.0226 7.26694L7.23925 13.0419C7.16201 13.1198 7.10091 13.2122 7.05944 13.3137C7.01797 13.4152 6.99695 13.5239 6.99758 13.6336ZM15.9643 6.67527L18.3226 9.03361L17.1392 10.2169L14.7809 7.85861L15.9643 6.67527ZM8.66425 13.9753L13.6059 9.03361L15.9643 11.3919L11.0226 16.3336H8.66425V13.9753Z"
        fill={fill}
      />
    </svg>
  );
}

function IconEdit(props) {
  const fill = props.fill || "#ffffff";
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11.0167C16.779 11.0167 16.567 11.1045 16.4107 11.2607C16.2545 11.417 16.1667 11.629 16.1667 11.85V17.8333C16.1667 18.0543 16.0789 18.2663 15.9226 18.4226C15.7663 18.5789 15.5543 18.6667 15.3333 18.6667H6.16667C5.94565 18.6667 5.73369 18.5789 5.57741 18.4226C5.42113 18.2663 5.33333 18.0543 5.33333 17.8333V8.66667C5.33333 8.44565 5.42113 8.23369 5.57741 8.07741C5.73369 7.92113 5.94565 7.83333 6.16667 7.83333H12.15C12.371 7.83333 12.583 7.74554 12.7393 7.58926C12.8955 7.43297 12.9833 7.22101 12.9833 7C12.9833 6.77899 12.8955 6.56702 12.7393 6.41074C12.583 6.25446 12.371 6.16667 12.15 6.16667H6.16667C5.50362 6.16667 4.86774 6.43006 4.3989 6.8989C3.93006 7.36774 3.66667 8.00363 3.66667 8.66667V17.8333C3.66667 18.4964 3.93006 19.1323 4.3989 19.6011C4.86774 20.0699 5.50362 20.3333 6.16667 20.3333H15.3333C15.9964 20.3333 16.6323 20.0699 17.1011 19.6011C17.5699 19.1323 17.8333 18.4964 17.8333 17.8333V11.85C17.8333 11.629 17.7455 11.417 17.5893 11.2607C17.433 11.1045 17.221 11.0167 17 11.0167ZM20.2667 4.18333C20.1821 3.97971 20.0203 3.8179 19.8167 3.73333C19.7165 3.69063 19.6089 3.66798 19.5 3.66667H14.5C14.279 3.66667 14.067 3.75446 13.9107 3.91074C13.7545 4.06702 13.6667 4.27899 13.6667 4.5C13.6667 4.72101 13.7545 4.93297 13.9107 5.08926C14.067 5.24554 14.279 5.33333 14.5 5.33333H17.4917L8.90833 13.9083C8.83023 13.9858 8.76823 14.078 8.72592 14.1795C8.68362 14.2811 8.66183 14.39 8.66183 14.5C8.66183 14.61 8.68362 14.7189 8.72592 14.8205C8.76823 14.922 8.83023 15.0142 8.90833 15.0917C8.9858 15.1698 9.07797 15.2318 9.17952 15.2741C9.28107 15.3164 9.38999 15.3382 9.5 15.3382C9.61001 15.3382 9.71893 15.3164 9.82048 15.2741C9.92203 15.2318 10.0142 15.1698 10.0917 15.0917L18.6667 6.50833V9.5C18.6667 9.72101 18.7545 9.93297 18.9107 10.0893C19.067 10.2455 19.279 10.3333 19.5 10.3333C19.721 10.3333 19.933 10.2455 20.0893 10.0893C20.2455 9.93297 20.3333 9.72101 20.3333 9.5V4.5C20.332 4.3911 20.3094 4.28352 20.2667 4.18333V4.18333Z"
        fill={fill}
      />
    </svg>
  );
}

function IconCart(props) {
  const fill = props.fill || "#000000";
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5765 7.17181H16.2431V6.33847C16.2431 5.67543 15.9797 5.03955 15.5109 4.57071C15.0421 4.10186 14.4062 3.83847 13.7431 3.83847H12.0765C11.4134 3.83847 10.7775 4.10186 10.3087 4.57071C9.83986 5.03955 9.57647 5.67543 9.57647 6.33847V7.17181H6.24313C6.02212 7.17181 5.81016 7.2596 5.65388 7.41588C5.4976 7.57216 5.4098 7.78413 5.4098 8.00514C5.4098 8.22615 5.4976 8.43811 5.65388 8.59439C5.81016 8.75068 6.02212 8.83847 6.24313 8.83847H7.07647V18.0051C7.07647 18.6682 7.33986 19.3041 7.8087 19.7729C8.27754 20.2417 8.91343 20.5051 9.57647 20.5051H16.2431C16.9062 20.5051 17.5421 20.2417 18.0109 19.7729C18.4797 19.3041 18.7431 18.6682 18.7431 18.0051V8.83847H19.5765C19.7975 8.83847 20.0094 8.75068 20.1657 8.59439C20.322 8.43811 20.4098 8.22615 20.4098 8.00514C20.4098 7.78413 20.322 7.57216 20.1657 7.41588C20.0094 7.2596 19.7975 7.17181 19.5765 7.17181ZM11.2431 6.33847C11.2431 6.11746 11.3309 5.9055 11.4872 5.74922C11.6435 5.59294 11.8555 5.50514 12.0765 5.50514H13.7431C13.9641 5.50514 14.1761 5.59294 14.3324 5.74922C14.4887 5.9055 14.5765 6.11746 14.5765 6.33847V7.17181H11.2431V6.33847ZM17.0765 18.0051C17.0765 18.2262 16.9887 18.4381 16.8324 18.5944C16.6761 18.7507 16.4641 18.8385 16.2431 18.8385H9.57647C9.35545 18.8385 9.14349 18.7507 8.98721 18.5944C8.83093 18.4381 8.74313 18.2262 8.74313 18.0051V8.83847H17.0765V18.0051Z" fill={fill} />
    </svg>

  );
}

function CloseElement (props) {
  const fill = props.fill || "#000000";
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F2F2F2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0503 9.94979C9.6598 10.3403 9.6598 10.9734 10.0503 11.3639L13.586 14.8996L10.0504 18.4352C9.65995 18.8257 9.65995 19.4588 10.0504 19.8493C10.4409 20.2398 11.074 20.2398 11.4645 19.8493L15.0001 16.3137L18.5347 19.8482C18.9252 20.2387 19.5583 20.2387 19.9487 19.8482C20.3392 19.4577 20.3392 18.8246 19.9487 18.4342L16.4142 14.8996L19.9489 11.3649C20.3394 10.9744 20.3394 10.3413 19.9489 9.95084C19.5584 9.56036 18.9253 9.56036 18.5348 9.95084L15.0001 13.4855L11.4643 9.94979C11.0739 9.55931 10.4408 9.55931 10.0503 9.94979Z" fill={fill}/>
    </svg>
  )
}

function ArrowUp (props) {
  const fill = props.fill || "#22CD52";
  return (
    <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9258 1.11707C19.8243 0.872721 19.6301 0.678545 19.3858 0.57707C19.2656 0.525829 19.1365 0.49865 19.0058 0.49707H14.0058C13.7406 0.49707 13.4862 0.602427 13.2987 0.789964C13.1112 0.9775 13.0058 1.23185 13.0058 1.49707C13.0058 1.76229 13.1112 2.01664 13.2987 2.20418C13.4862 2.39171 13.7406 2.49707 14.0058 2.49707H16.5958L11.0058 8.08707L7.7158 4.78707C7.62284 4.69334 7.51223 4.61895 7.39038 4.56818C7.26852 4.51741 7.13781 4.49127 7.0058 4.49127C6.87379 4.49127 6.74308 4.51741 6.62122 4.56818C6.49936 4.61895 6.38876 4.69334 6.2958 4.78707L0.295798 10.7871C0.20207 10.88 0.127676 10.9906 0.0769069 11.1125C0.0261382 11.2344 0 11.3651 0 11.4971C0 11.6291 0.0261382 11.7598 0.0769069 11.8816C0.127676 12.0035 0.20207 12.1141 0.295798 12.2071C0.388761 12.3008 0.499363 12.3752 0.621222 12.426C0.743081 12.4767 0.873786 12.5029 1.0058 12.5029C1.13781 12.5029 1.26852 12.4767 1.39037 12.426C1.51223 12.3752 1.62284 12.3008 1.7158 12.2071L7.0058 6.90707L10.2958 10.2071C10.3888 10.3008 10.4994 10.3752 10.6212 10.426C10.7431 10.4767 10.8738 10.5029 11.0058 10.5029C11.1378 10.5029 11.2685 10.4767 11.3904 10.426C11.5122 10.3752 11.6228 10.3008 11.7158 10.2071L18.0058 3.90707V6.49707C18.0058 6.76229 18.1112 7.01664 18.2987 7.20418C18.4862 7.39171 18.7406 7.49707 19.0058 7.49707C19.271 7.49707 19.5254 7.39171 19.7129 7.20418C19.9004 7.01664 20.0058 6.76229 20.0058 6.49707V1.49707C20.0042 1.36639 19.977 1.23729 19.9258 1.11707Z" fill={fill}/>
    </svg>

  )
}

function SvgProgressPlus (props) {
  const fill = props.fill || "#22CD52";
  return (
    <svg className="statistic-card__svg" width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9258 1.11707C19.8243 0.872721 19.6301 0.678545 19.3858 0.57707C19.2656 0.525829 19.1365 0.49865 19.0058 0.49707H14.0058C13.7406 0.49707 13.4862 0.602427 13.2987 0.789964C13.1112 0.9775 13.0058 1.23185 13.0058 1.49707C13.0058 1.76229 13.1112 2.01664 13.2987 2.20418C13.4862 2.39171 13.7406 2.49707 14.0058 2.49707H16.5958L11.0058 8.08707L7.7158 4.78707C7.62284 4.69334 7.51223 4.61895 7.39038 4.56818C7.26852 4.51741 7.13781 4.49127 7.0058 4.49127C6.87379 4.49127 6.74308 4.51741 6.62122 4.56818C6.49936 4.61895 6.38876 4.69334 6.2958 4.78707L0.295798 10.7871C0.20207 10.88 0.127676 10.9906 0.0769069 11.1125C0.0261382 11.2344 0 11.3651 0 11.4971C0 11.6291 0.0261382 11.7598 0.0769069 11.8816C0.127676 12.0035 0.20207 12.1141 0.295798 12.2071C0.388761 12.3008 0.499363 12.3752 0.621222 12.426C0.743081 12.4767 0.873786 12.5029 1.0058 12.5029C1.13781 12.5029 1.26852 12.4767 1.39037 12.426C1.51223 12.3752 1.62284 12.3008 1.7158 12.2071L7.0058 6.90707L10.2958 10.2071C10.3888 10.3008 10.4994 10.3752 10.6212 10.426C10.7431 10.4767 10.8738 10.5029 11.0058 10.5029C11.1378 10.5029 11.2685 10.4767 11.3904 10.426C11.5122 10.3752 11.6228 10.3008 11.7158 10.2071L18.0058 3.90707V6.49707C18.0058 6.76229 18.1112 7.01664 18.2987 7.20418C18.4862 7.39171 18.7406 7.49707 19.0058 7.49707C19.271 7.49707 19.5254 7.39171 19.7129 7.20418C19.9004 7.01664 20.0058 6.76229 20.0058 6.49707V1.49707C20.0042 1.36639 19.977 1.23729 19.9258 1.11707Z" fill={fill}/>
    </svg>
  )
}

function SvgProgressMinus (props) {
  const fill = props.fill || "#FE3434";
  return (
    <svg className="statistic-card__svg" width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9258 11.8829C19.8243 12.1273 19.6301 12.3215 19.3858 12.4229C19.2656 12.4742 19.1365 12.5013 19.0058 12.5029H14.0058C13.7406 12.5029 13.4862 12.3976 13.2987 12.21C13.1112 12.0225 13.0058 11.7681 13.0058 11.5029C13.0058 11.2377 13.1112 10.9834 13.2987 10.7958C13.4862 10.6083 13.7406 10.5029 14.0058 10.5029H16.5958L11.0058 4.91293L7.7158 8.21293C7.62284 8.30666 7.51223 8.38105 7.39038 8.43182C7.26852 8.48259 7.13781 8.50873 7.0058 8.50873C6.87379 8.50873 6.74308 8.48259 6.62122 8.43182C6.49936 8.38105 6.38876 8.30666 6.2958 8.21293L0.295798 2.21293C0.20207 2.11997 0.127676 2.00937 0.0769069 1.88751C0.0261382 1.76565 0 1.63494 0 1.50293C0 1.37092 0.0261382 1.24021 0.0769069 1.11835C0.127676 0.996493 0.20207 0.885894 0.295798 0.792931C0.388761 0.699203 0.499363 0.624806 0.621222 0.574038C0.743081 0.523269 0.873786 0.497131 1.0058 0.497131C1.13781 0.497131 1.26852 0.523269 1.39037 0.574038C1.51223 0.624806 1.62284 0.699203 1.7158 0.792931L7.0058 6.09293L10.2958 2.79293C10.3888 2.6992 10.4994 2.62481 10.6212 2.57404C10.7431 2.52327 10.8738 2.49713 11.0058 2.49713C11.1378 2.49713 11.2685 2.52327 11.3904 2.57404C11.5122 2.62481 11.6228 2.6992 11.7158 2.79293L18.0058 9.09293V6.50293C18.0058 6.23771 18.1112 5.98336 18.2987 5.79582C18.4862 5.60829 18.7406 5.50293 19.0058 5.50293C19.271 5.50293 19.5254 5.60829 19.7129 5.79582C19.9004 5.98336 20.0058 6.23771 20.0058 6.50293V11.5029C20.0042 11.6336 19.977 11.7627 19.9258 11.8829Z" fill={fill}/>
    </svg>
  )
}

function StorytaleLogo() {
  return (
    <svg width="148" height="40" viewBox="0 0 148 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.496 17.792L61.232 16.6639C60.368 14.384 57.536 13.448 55.712 13.664C52.976 14 51.416 15.9439 51.416 17.7199C51.416 19.1119 52.736 20.384 54.2 20.84C55.424 21.224 58.448 21.32 58.304 22.712C58.208 23.6 56.744 23.8879 55.784 23.672C54.824 23.4559 54.08 22.688 53.792 21.92L50.96 23.144C51.56 24.656 53.168 26.504 56.36 26.504C59.84 26.504 61.472 24.392 61.472 22.784C61.472 21.2 60.68 19.6399 58.088 19.016C55.592 18.4159 54.512 18.2959 54.512 17.408C54.512 16.784 55.448 16.4479 56.264 16.4479C56.912 16.4479 58.112 16.808 58.496 17.792Z" fill="black"/>
      <path d="M70.7795 25.76L69.8915 23.024C69.3875 23.336 68.3555 23.5279 67.8035 22.9039C67.4675 22.5199 67.4195 22.184 67.4195 20.528V17.024H70.2515V14.2639H67.4435V10.7119H64.3955V13.472C64.3955 14 64.2275 14.2639 63.4835 14.2639H62.1875V17.024H64.2515V22.5199C64.2515 23.8879 65.0435 25.784 67.2035 26.216C68.9795 26.576 69.8435 26.12 70.7795 25.76Z" fill="black"/>
      <path d="M70.789 20.12C70.789 23.576 73.525 26.528 77.101 26.528C80.653 26.528 83.389 23.576 83.389 20.12C83.389 16.6639 80.653 13.8559 77.101 13.8559C73.525 13.8559 70.789 16.6639 70.789 20.12ZM73.957 20.1439C73.957 18.1279 75.493 16.7599 77.125 16.7599C78.757 16.7599 80.269 18.1279 80.269 20.1439C80.269 22.3039 78.757 23.576 77.125 23.576C75.493 23.576 73.957 22.3039 73.957 20.1439Z" fill="black"/>
      <path d="M87.9603 26.096L84.8403 26.072V14.3359H87.9603V16.0879C88.3443 15.1519 88.9203 14.576 90.0963 14.1439C90.9603 13.8319 92.4243 13.904 93.2403 14.3839L92.3763 17.24C91.4163 16.784 89.8323 16.7119 88.9203 17.7439C88.1043 18.6799 87.9603 19.3759 87.9603 22.4959V26.096Z" fill="black"/>
      <path d="M95.4158 31.424H98.8718L106.288 14.2639H102.832L99.8798 21.584L96.6878 14.2639L93.2318 14.24L98.1518 25.5919L95.4158 31.424Z" fill="black"/>
      <path d="M115.359 25.76L114.471 23.024C113.967 23.336 112.935 23.5279 112.383 22.9039C112.047 22.5199 111.999 22.184 111.999 20.528V17.024H114.831V14.2639H112.023V10.7119H108.975V13.472C108.975 14 108.807 14.2639 108.063 14.2639H106.767V17.024H108.831V22.5199C108.831 23.8879 109.623 25.784 111.783 26.216C113.559 26.576 114.423 26.12 115.359 25.76Z" fill="black"/>
      <path d="M120.578 26.4799C122.306 26.4799 123.386 25.496 123.914 24.752L124.01 26.12H127.154L127.082 18.2479C127.082 16.5679 126.146 15.0319 123.962 14.216C122.306 13.592 120.29 13.832 119.066 14.528C117.362 15.488 116.714 16.784 116.546 17.768L119.354 18.368C119.642 16.832 120.842 16.184 122.354 16.568C124.01 16.9999 123.746 18.368 123.41 18.632C123.074 18.896 121.682 19.016 120.506 19.184C117.914 19.52 116.09 20.696 116.354 23.312C116.618 25.256 118.034 26.4799 120.578 26.4799ZM121.946 23.96C120.53 24.248 119.498 23.816 119.498 22.6639C119.498 21.056 121.394 21.632 123.962 21.224C124.13 22.424 123.482 23.648 121.946 23.96Z" fill="black"/>
      <path d="M132.885 8.64795H129.549V26.1679H132.885V8.64795Z" fill="black"/>
      <path d="M134.8 20.1439C134.8 15.8479 137.92 13.904 141.112 13.904C144.28 13.904 147.376 15.848 147.016 20.984L138.064 21.08C138.064 22.016 138.952 23.552 140.92 23.672C142.36 23.768 143.272 23.096 143.8 22.088L146.752 23C146.152 24.464 144.28 26.336 141.16 26.336C137.32 26.336 134.8 23.5759 134.8 20.1439ZM138.064 18.7039L143.704 18.68C143.704 17.552 142.504 16.3279 140.632 16.4479C139.504 16.5199 138.208 17.36 138.064 18.7039Z" fill="black"/>
      <g clipPath="url(#clip0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.77469 5.0792C0.848633 7.96175 0.848633 11.9745 0.848633 20C0.848633 28.0255 0.848633 32.0382 2.77469 34.9208C3.6085 36.1687 4.67994 37.2401 5.92783 38.0739C8.81038 40 12.8231 40 20.8486 40C28.8741 40 32.8868 40 35.7694 38.0739C37.0173 37.2401 38.0887 36.1687 38.9225 34.9208C40.8486 32.0382 40.8486 28.0255 40.8486 20C40.8486 11.9745 40.8486 7.96175 38.9225 5.0792C38.0887 3.83131 37.0173 2.75987 35.7694 1.92606C32.8868 0 28.8741 0 20.8486 0C12.8231 0 8.81038 0 5.92783 1.92606C4.67994 2.75987 3.6085 3.83131 2.77469 5.0792ZM19.8983 5.51184H21.5263L25.751 13.3015L30.5435 9.20594L31.6425 10.3049L27.5421 15.0925L35.3364 19.3219V20.9501L27.5657 17.7027L31.5771 29.7604L30.4215 30.9161L25.72 19.5037L21.576 34.4882H19.978L23.1778 19.4593L11.1629 30.8041L10.0442 29.6854L21.3842 17.6658L6.36004 20.8703V19.2722L21.3397 15.1236L9.93208 10.4268L11.0877 9.2711L23.1409 13.2778L19.8983 5.51184ZM11.1689 9.61422L23.6717 13.7704L11.1689 9.61422ZM35.0363 19.5005L35.0364 20.4996V19.5005L27.0729 15.1792L35.0363 19.5005ZM21.3477 5.81184H20.3486H21.3477L25.6641 13.7704L21.3477 5.81184Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="40" height="40" fill="white" transform="translate(0.848633)"/>
        </clipPath>
      </defs>
    </svg>

  )
}


export { IconPlus, IconEscape, IconOpen, IconEdit, IconCart, NotificationClose, CloseElement, StorytaleLogo, SvgProgressMinus, SvgProgressPlus, ArrowUp };
