import React, { Component } from "react";
import { withRouter } from "react-router";
import NavSidebar from "../../components/NavSidebar";
import TableWrapper from "../../components/TableWrapper";
import HeaderPanel from "../../components/HeaderPanel";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import axios from "axios";
import moment from "moment";
import "../../base-css.css";
import { Table, Pagination, Avatar } from "antd";
import { openNotificationWithIcon } from "../../lib/notifications";


const columns = [
  {
    title: "",
    key: "name",
    dataIndex: "cover",
    render: (text, record) => (
      <>
        <Avatar shape="square" size={50} src={record.cover} />
      </>
    )
  },
  {
    title: "Name",
    key: "name",
    render: (text, record) => (
      <>
        <span style={{ color: "#2C3FEE" }}>{record.name}</span>
      </>
    )
  },
  {
    title: "Pack",
    key: "pack",
    render: (text, record) => (
      <>
        <span style={{ color: "#2C3FEE" }}>{record.pack.name}</span>
      </>
    )
  },
  {
    title: "Author",
    dataIndex: "",
    key: "name"
  },
  {
    title: "Creating date",
    dataIndex: "createdDate",
    key: "packName",
    render: (text, record) => (
      <>
        {moment(record.createdDate).format('Do MMM YY')}
      </>
    )
  },
  {
    title: "Visitors",
    dataIndex: "visitors",
    key: "visitors"
  },
  {
    title: "Downloads",
    dataIndex: "downloadCount",
    key: "downloadCount"
  },
];

class Illustrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      allPage: 1,
      to: null,
      from: null,
      allCount: null,
      params: {
        from: moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        page: 1
      }
    };

    this.onChangePagination = this.onChangePagination.bind(this);
    this.onChangeRequest = this.onChangeRequest.bind(this);
    this.onChangeCalendarFrom = this.onChangeCalendarFrom.bind(this);
    this.onChangeCalendarTo = this.onChangeCalendarTo.bind(this);
  }

  onChangePagination(page) {
    this.setState(
      prevState => {
        let params = Object.assign({}, prevState.params);
        params.page = page;
        return { params };
      },
      () => this.onChangeRequest(this.state.params)
    );
  }

  onChangeCalendarFrom(date) {
    if (date === null) {
      return;
    }

    const modernDate = moment(date._d).format("YYYY-MM-DD");

    this.setState(
      prevState => {
        let params = Object.assign({}, prevState.params);
        params.from = modernDate;
        return { params };
      },
      () => this.onChangeRequest(this.state.params)
    );
  }

  onChangeCalendarTo(date) {
    if (date === null) {
      return;
    }

    const modernDate = moment(date._d).format("YYYY-MM-DD");

    this.setState(
      prevState => {
        let params = Object.assign({}, prevState.params);
        params.to = modernDate;
        return { params };
      },
      () => this.onChangeRequest(this.state.params)
    );
  }

  onChangeRequest(reguest) {
    window.scrollTo(0, 0);

    let search = {
      params: reguest
    };

    axios
      .get(`/api/illustration`, search)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            data: response.data.result.illustrationData,
            from: response.data.result.pagination.from,
            to: response.data.result.pagination.to,
          });
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {

    let search = {
      params: this.state.params
    };

    axios
      .get(`/api/illustration`, search)
      .then(response => {
        if (response.data.success === true) {

          const { illustrationData, pagination } = response.data.result;
          const { allPage, from, to, allCount } = pagination;
          this.setState({
            data: illustrationData,
            allPage: allPage,
            from: from,
            to: to,
            allCount: allCount
          });
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const {
      data,
      allPage,
      from,
      to,
      allCount,
    } = this.state;
    return (
      <>
      <Header/>
          <div className="container-custom">
            <div className="row">
              <div className="col-lg-2 col-md-3" style={{ marginTop: "70px" }}>
                <NavSidebar />
              </div>
              <div className="col" style={{ marginTop: "70px" }}>
                <h3>Assets</h3>

                <HeaderPanel
                  from={from}
                  to={to}
                  allCount={allCount}
                  onChangeCalendarFrom={this.onChangeCalendarFrom}
                  onChangeCalendarTo={this.onChangeCalendarTo}
                />

                <div style={{ marginTop: "60px" }}>
                <TableWrapper>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                  {allPage > 1 ? (
                    <div style={{ marginTop: "40px" }}>
                      <Pagination
                        defaultCurrent={1}
                        total={allPage + "0"}
                        onChange={this.onChangePagination}
                      />
                    </div>
                  ) : null}
                </TableWrapper>
                </div>
              </div>
            </div>
          </div>
        <Footer bordered={true} allCount={allCount} />
      </>
    );
  }
}

const IllustrationsRouter = withRouter(Illustrations);
export default IllustrationsRouter;
