import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { authorizeAction } from '../../actions';

import axios from "axios";

// Redux mapStateToProps
const mapStateToProps = state => ({ 
  authorized: state.authorizeReducer.authorized,
})

async function userLogout(props, history) {
  axios.post('/api/user/logout')
    .then(response => {
      if (response.data) {
        props.dispatch(authorizeAction());
        history.push('/login');
      }
    })
    .catch(function (error) {
      console.log(error);
    })
}

function LogOut(props) {
  const history = useHistory();

  useEffect(() => {
    userLogout(props, history);
  })
  
  return (null);
}

export default connect(mapStateToProps)(LogOut);
