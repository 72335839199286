import React from "react";

import "./ButtonIcon.css";

export default function ButtonIcon(props) {
  return (
    <div className={`button-icon ${props.class}`}>
      {props.children}
    </div>
  );
}
