import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

import NavSidebar from "../../components/NavSidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TableWrapper from "../../components/TableWrapper";
import StatisticCard from "../../components/StatisticCard";
import Calendar from "../../components/Calendar";
import PeriodTabs from "../../components/PeriodTabs";
import PeriodTabsItem from "../../components/PeriodTabsItem";
import ButtonIcon from "../../components/ButtonIcon";
import {IconEscape, IconEdit} from "../../components/Icons";
import { openNotificationWithIcon } from "../../lib/notifications";

import { Table, DatePicker } from "antd";
import axios from "axios";
import moment from "moment";

import { getRequestPeriod } from "../../utils/utils";

import "./index.css";
import "../../base-css.css";

const datePickerDateFormat = "Do MMM YY";

const requestParams = [
  { 'id': 1, 'name': 'activeSubscriptions' },
  { 'id': 2, 'name': 'newActivation' },
  { 'id': 3, 'name': 'revenue' },
]

const planColumns = [
  {
    title: "Price",
    key: "name",
    render: (text, record) => (
      <>
        <span>{record.price.toFixed(2)}</span>
      </>
    )
  },
  {
    title: "Duration",
    key: "duration",
    render: (text, record) => (
      <>
        <span>{record.durationCount}</span>
        &nbsp;
        <span>{record.durationLabel}</span>
      </>
    )
  },
  {
    title: "Charge period",
    key: "charge",
    render: (text, record) => (
      <>
        <span>{record.chargePeriodCount}</span>
        &nbsp;
        <span>{record.chargePeriodLabel}</span>
      </>
    )
  },
  {
    title: "Download limit",
    dataIndex: "downloadLimit",
    key: "downloadLimit"
  },
  {
    title: "Creation date",
    key: "createdDate",
    render: () => "No status",
  }
];

class ViewPlan extends Component {
  constructor(props) {
    super(props);
      this.state = {
        data: [],
        fixedPeriod: "Month",
        error: false,
        planInfo: {
          name: "", description: "",
        },
        planData: [],
        pagination: {
          params: {
            to: null,
            from: null,
            allCount: null,
            allPage: 1,
            currentPage: 1
          }
        },
        previous: {
          params: {
            from: moment()
              .subtract(2, "month")
              .format("YYYY-MM-DD"),
            to: moment()
              .subtract(1, "month")
              .format("YYYY-MM-DD")
          },
          data: []
        },
        current: {
          params: {
            from: moment()
              .subtract(1, "month")
              .format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD")
          },
          data: []
        },
        singlePeriod: false,
        duringDays: ""
      };

    this.getStatistic = this.getStatistic.bind(this);
    this.getPlanInfo = this.getPlanInfo.bind(this);
    this.calendarOnChange = this.calendarOnChange.bind(this);
    this.changeRequest = this.changeRequest.bind(this);
    this.onChangePagination = this.onChangePagination.bind(this);
  }

  onChangePagination(page) {
    this.setState(
      prevState => {
        let pagination = Object.assign({}, prevState.pagination);
        pagination.params.currentPage = page;
        return { pagination };
      }, () => this.getIllustrations()
    );
  }

  getPlanInfo() {
    axios
      .get(`/api/subscriptionPlan/${this.props.match.params.id}`)
      .then(response => {

      const dataObject = response.data.result.subscriptionPlan;

      if (dataObject === null) {
          this.setState({
              error: true
          });
      }
      if (response.data.success && (dataObject !== null)) {
          const data = response.data.result.subscriptionPlan;
          const {name} = response.data.result.subscriptionPlan;
          const {description} = response.data.result.subscriptionPlan;

          this.setState(prevState => {
            let planInfo = Object.assign({}, prevState.planInfo);
            planInfo.name = name;
            planInfo.description = description;

            return {
                planInfo,
                planData: [data]
            };
          });
      } else {
        openNotificationWithIcon(
          "error",
          "Error",
          response.data.message
        );
      }
      })
      .catch(function (error) {
          console.log(error);
    });
  }

    getStatistic(requestParams, targetStat) {
        axios
            .get(
                `/api/statistic/getSubscriptionPlanStatistic?subscriptionPlanId=${this.props.match.params.id}`,
                { params: requestParams },
                { timeout: 3000 }
            )
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        [targetStat]: {
                            params: requestParams,
                            data: response.data.result.subscriptionPlanStatistic
                }
                    });
                } else {
                    openNotificationWithIcon(
                        "error",
                        "Error",
                        response.data.message
                    );
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    changeRequest(value) {
        const dataObject = getRequestPeriod(value);

        this.setState(
            prevState => {
                let current = Object.assign({}, prevState.current);
                current.params.from = dataObject.curFrom;
                current.params.to = dataObject.curTo;

                let previous = Object.assign({}, prevState.previous);
                previous.params.from = dataObject.prevFrom;
                previous.params.to = dataObject.prevTo;

                return {
                    current,
                    previous,
                    singlePeriod: false,
                    fixedPeriod: dataObject.fixedPeriod
                };
            },
            () => {
                this.getStatistic(this.state.current.params, "current");
                this.getStatistic(this.state.previous.params, "previous");
                this.getStatistic();
            }
        );
    }

    calendarOnChange(date, dateString, pickerInd) {
        this.setState(
            prevState => {
                let current = Object.assign({}, prevState.current);

                return {
                    current,
                    fixedPeriod: null,
                    singlePeriod: true
                };
            },
            () => {
                this.getStatistic(this.state.current.params, "current");
                this.setState({
                    duringDays: this.countDays(
                        this.state.current.params.from,
                        this.state.current.params.to
                    )
                });
            }
        );
    }

    countDays(start, end) {
        return moment.duration(moment(end).diff(moment(start))).asDays();
    }

    componentDidMount() {
        this.getPlanInfo();
        this.getStatistic(this.state.previous.params, "previous");
        this.getStatistic(this.state.current.params, "current");
    }

    render() {
        const { allCount, planInfo, planData, error } = this.state;
        const { name } = planInfo;
        const { description } = planInfo;

        return (
            <div className="view-plan">
                {error === true ? <Redirect to="/404" /> : null}
                <Header />
                <div className="container-custom">
                    <div className="row">
                        <div className="col-lg-2 col-md-3" style={{ marginTop: "70px" }}>
                            <NavSidebar />
                        </div>
                        <div className="col" style={{ marginTop: "70px" }}>
                            <div className="row">
                                <div className="col-12">
                                    <Link
                                        to={`/plans`}
                                    >
                                        <ButtonIcon class="neutral outline escape">
                                          <span>
                                            <IconEscape />
                                          </span>
                                            <span>Plans</span>
                                        </ButtonIcon>
                                    </Link>
                                </div>
                            </div>

                            <h3 className="plan-name">{name}</h3>
                            <p className="plan-description">{description}</p>

                            <div className="row table-row">
                                <div
                                    className="col-lg-8 left-column"
                                    style={{ marginTop: "40px" }}
                                >
                                    <TableWrapper>
                                        <Table
                                            className="no-border"
                                            columns={planColumns}
                                            dataSource={planData}
                                            pagination={false}
                                        />
                                    </TableWrapper>
                                </div>
                                <div
                                    className="col-lg-4 right-column"
                                    style={{ marginTop: "40px" }}
                                >
                                    <div className="button-wrapper">
                                        <Link
                                            to={`/plan/edit/${this.props.match.params.id}`}
                                        >
                                            <ButtonIcon class="black small">
                                              <span>
                                                <IconEdit />
                                               </span>
                                                <span>Edit plan</span>
                                            </ButtonIcon>
                                        </Link>

                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div
                                        style={{
                                            borderBottom: "1px solid #f2f2f2",
                                            paddingTop: "25px"
                                        }}
                                    ></div>
                                </div>
                            </div>

                            <h4 style={{ marginTop: "70px" }}>Statistic</h4>
                            <div className="row">
                                <div className="col-auto" style={{ marginTop: "30px" }}>
                                    <PeriodTabs>
                                        <PeriodTabsItem
                                            isActive={this.state.fixedPeriod === "Day"}
                                            onClick={value => this.changeRequest(value)}
                                            name="Day"
                                        />
                                        <PeriodTabsItem
                                            isActive={this.state.fixedPeriod === "Week"}
                                            onClick={value => this.changeRequest(value)}
                                            name="Week"
                                        />
                                        <PeriodTabsItem
                                            isActive={this.state.fixedPeriod === "Month"}
                                            onClick={value => this.changeRequest(value)}
                                            name="Month"
                                        />
                                        <PeriodTabsItem
                                            isActive={this.state.fixedPeriod === "Year"}
                                            onClick={value => this.changeRequest(value)}
                                            name="Year"
                                        />
                                    </PeriodTabs>
                                </div>
                                <div className="col-auto" style={{ marginTop: "30px" }}>
                                    <Calendar
                                        startDatePicker={
                                            <DatePicker
                                                value={moment(this.state.current.params.from)}
                                                defaultValue={moment(this.state.current.params.from)}
                                                format={datePickerDateFormat}
                                                onChange={(date, dateString) =>
                                                    this.calendarOnChange(date, dateString, "fromPicker")
                                                }
                                                allowClear={false}
                                            />
                                        }
                                        endDatePicker={
                                            <DatePicker
                                                value={moment(this.state.current.params.to)}
                                                defaultValue={moment(this.state.current.params.to)}
                                                format={datePickerDateFormat}
                                                onChange={(date, dateString) =>
                                                    this.calendarOnChange(date, dateString, "toPicker")
                                                }
                                                allowClear={false}
                                            />
                                        }
                                    />
                                </div>
                            </div>


                            <div style={{ marginTop: "50px" }}>
                                <div className="row statistic-row">
                                            {this.state.current.data !== null && Object.keys(this.state.current.data).filter(element => {
                                                return element !== 'subscriptionPlanId'
                                            }).map(
                                                (keyName, index) => (
                                                    <div className="col-lg-4 col-md-6 item" key={index}>
                                                        <StatisticCard
                                                            name={keyName.replace(/([A-Z])/g, ' $1').trim()}
                                                            value={this.state.current.data !== null ? this.state.current.data[keyName] : 0}
                                                            prevValue={this.state.previous.data !== null ? this.state.previous.data[keyName] : 0}
                                                            isSingle={this.state.singlePeriod}
                                                            days={Math.trunc(this.state.duringDays)}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            {this.state.current.data === null && requestParams.map(
                                                (keyName, index) => (
                                                    <div className="col-lg-4 col-md-6 item" key={index}>
                                                        <StatisticCard
                                                            name={keyName.name.replace(/([A-Z])/g, ' $1').trim()}
                                                            value={0}
                                                            prevValue={0}
                                                            isSingle={this.state.singlePeriod}
                                                            days={Math.trunc(this.state.duringDays)}
                                                        />
                                                    </div>
                                                )
                                            )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer bordered={true} allCount={allCount} />
            </div>
        );
    }
}

export default ViewPlan;
