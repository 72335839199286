import { Redirect, Route } from "react-router";

import ErrorBoundary from './ErrorBoundary';

export const PrivateRoute = ({component: Component, ...rest}) => (
  <Route {...rest} render={props => (
    localStorage.getItem('storyAdminUser') === 'true'
    ? <ErrorBoundary><Component {...props} /></ErrorBoundary>
    : <Redirect to='/login' />
  )} />
)
