import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.css';

export default function Calendar(props) {
  return (
    <div className='calendar'>
      <div className='calendar__wrapper'>
        <div className='calendar__field'>
          <p className='calendar__label'>From</p>
          {props.startDatePicker}
        </div>
        <div className='calendar__field'>
          <p className='calendar__label'>To</p>
          {props.endDatePicker}
        </div>
      </div>
    </div>
  );
}

